import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { withFirebase } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Input } from '../../components/Forms';
import Spinner from '../../components/Spinner';
import Logo from '../../assets/Logo.png';
import qs from 'qs';

const ResetPassword = ({ firebase, auth, history, location, profile }) => {
  const [authError, setAuthError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [validateOobCode, setvalidateOobCode] = useState(false);
  const redirect = location.search && location.search ? qs.parse(location.search, { ignoreQueryPrefix: true }).redirectUri : null;
  const oobCode = location.search && location.search ? qs.parse(location.search, { ignoreQueryPrefix: true }).oobCode : null;

  useEffect(() => {
    if (oobCode && !validateOobCode) {
      firebase
        .auth()
        .verifyPasswordResetCode(oobCode)
        .then(res => {
          setvalidateOobCode(true);
          setAuthError(null);
        })
        .catch(e => {
          console.error(e);
          setvalidateOobCode(false);
          setAuthError(e.message);
        });
    }
  }, [firebase, oobCode, validateOobCode]);

  useEffect(() => {
    const redirectToHome = async () => {
      history.push('/')
    };
    const redirectToPath = async () => {
      history.push(redirect)
    };

    if (auth && profile && profile.isLoaded) {
      if (redirect) {
        redirectToPath()
      } else {
        redirectToHome();
      }
    } else return null;
  }, [auth, history, profile, redirect]);

  const onSubmit = values => {
    setSubmitting(true);
    const { password } = values;
    firebase.auth().confirmPasswordReset(oobCode, password).then(() => {
      history.push('/login');
    }).catch(e => {
      setAuthError(e.message);
    })
  };

  const validate = values => {
    const errors = {};
    if (!values.password) {
      errors.password = 'Please input a password'
    }
    if (!values.passwordConfirm) {
      errors.passwordConfirm = 'Please confirm your password'
    }
    return errors;
  };

  const renderAuthError = () => {
    if (authError) {
      return (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              {/* <!-- Heroicon name: check-circle --> */}
              <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-red-800">
                {authError}
              </p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  onClick={() => setAuthError(null)}
                  className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                >
                  <span className="sr-only">Dismiss</span>
                  {/* <!-- Heroicon name: x --> */}
                  <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-16 w-auto" src={Logo} alt="Save My Wordle" />
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md px-2">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {renderAuthError()}
          {validateOobCode
            ? (
              <>
                <div>
                  <h2 className="text-center text-xl font-bold text-gray-900">
                    Reset Password
                  </h2>
                </div>
                <Formik
                  initialValues={{ password: '', passwordConfirm: '' }}
                  onSubmit={onSubmit}
                  validate={validate}
                >
                  <Form className="space-y-6" action="#" method="POST">
                    <div className='mt-6'>
                      <Field
                        name='password'
                        label='Password'
                        type='password'
                        component={Input}
                      />
                    </div>
                    <div className='mt-6'>
                      <Field
                        name='passwordConfirm'
                        label='Confirm Password'
                        type='password'
                        component={Input}
                      />
                    </div>
                    <div>
                      <button type="submit" className="w-full btn-primary flex justify-center" disabled={submitting}>
                        {submitting
                          ? <Spinner height={20} color="#FFFFFF" />
                          : 'Submit'
                        }
                      </button>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="text-sm">
                        <Link to="/login" className="font-medium text-primary-600 hover:text-primary-500">
                          Back to login
                        </Link>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </>
            ) : (
              <Link to="/login" className="mt-4 w-full btn-primary flex justify-center">
                Back to login
              </Link>
            )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth.uid,
    profile: state.firebase.profile,
  };
};

export default withRouter(connect(mapStateToProps)(withFirebase(ResetPassword)));