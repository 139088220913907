import React, { useContext, useEffect, useState } from 'react';
import Logo from '../../assets/Logo.png';
import { DuplicateIcon } from '@heroicons/react/outline';
import Screenshot from '../../assets/ShareScreenshot.png';
import SendScreenshot from '../../assets/sendWordle.jpg';
import Contact from '../../assets/Contact.jpg';
import { AppContext } from '../../contexts';
import ScoreSubmit from '../../assets/ScoreSubmit.png';
import { Link } from 'react-router-dom';
import { ExclamationIcon } from '@heroicons/react/solid';

const textSteps = [
  {
    step: '1',
    question: '1. Save the Wordle texting number as a contact.',
    answer: 'Save the number above as a contact to make it easier to send scores to us after you complete your wordle.',
    img: Contact
  },
  {
    step: '2',
    question: '2. After completing your Wordle, click "Share".',
    answer: 'After finishing your wordle, you will get a popup showing your score and some basic stats. On this page, there will be a red button labeled "Share". Clicking on this will copy your score to your clipboard.',
    img: Screenshot
  },
  {
    step: '3',
    question: '3. Paste your copied score into a message and send to the Wordle texting number.',
    answer: 'Paste the copied score in the exact format that it was copied from Wordle into a message. It is important that it stays in the exact format, otherwise an error may happen. Once it is pasted, press send and head to app.SaveMyWordle.com to view your score history and compete with friends!',
    img: SendScreenshot
  }
]

const appSteps = [
  {
    step: '1',
    question: '1. Click on "My Scores".',
    answer: 'Click on "My Scores" in the navigation menu or sidebar',
  },
  {
    step: '2',
    question: '2. Click on "Add Score"',
    answer: 'At the top of all of your scores, click on the button to add a score.',
  },
  {
    step: '3',
    question: '3. Paste your copied score into the box and press save.',
    answer: 'Paste the copied score in the exact format that it was copied from Wordle. It is important that it stays in the exact format, otherwise an error may happen. Once it is pasted, press save.',
    img: ScoreSubmit
  }
]

const Instructions = () => {
  const [loggedMixpanel, setLoggedMixpanel] = useState(false);
  const { userId, mixpanel, userInfo } = useContext(AppContext);

  useEffect(() => {
    if (!loggedMixpanel && userId) {
      mixpanel.track('View instructions', {
        distinct_id: userId
      });
      setLoggedMixpanel(true);
    }
  }, [loggedMixpanel, mixpanel, userId]);

  return (
    <section aria-labelledby="faq-heading" className="">
      <div className="">
        <div className="max-w-xl">
          <img src={Logo} className="h-20" alt="Save My Wordle Logo" />
          <h2 id="faq-heading" className="mt-4 text-2xl font-extrabold tracking-tight text-gray-900">
            How To Save Scores
          </h2>
          <p className="mt-4 text-base text-gray-500">
            Wordle does not have an out of the box solution to save scores, so we had to get creative. We have two ways that you can save your wordle scores:
          </p>
          <ul className="mt-4 text-base font-medium text-gray-500">
            <li>1. Submit them through the online app.</li>
            <li>2. Text your scores to us.</li>
          </ul>
          <p className="mt-4 text-base text-gray-500">This page will walk you step-by-step on how to submit your scores to us so we can save them.</p>
        </div>
        <div className="mt-12">
          <div>
            <h2 className="text-xl font-extrabold tracking-tight text-gray-900 pb-2">
              Option 1: Submit scores online.
            </h2>
            <Link
              to="/scores/submit"
              className="font-semibold text-primary-900 hover:text-primary-1100"
            >
              Submit A Score &#8594;
            </Link>
          </div>
          <dl className="mt-12 grid grid-cols-1 gap-y-10 sm:mt-12 md:grid-cols-2 md:gap-x-6 lg:grid-cols-3">
            {appSteps.map((step) => (
              <div key={step.step}>
                <dt className="text-base font-medium text-gray-900">{step.question}</dt>
                <dd className="mt-3 text-sm text-gray-500">
                  <span>
                    {step.answer}
                  </span>
                  {
                    step.img
                      ? (
                        <img src={step.img} className="rounded-lg mt-4" alt={step.question} />
                      ) : null
                  }
                </dd>
              </div>
            ))}
          </dl>
        </div>
        <div className="mt-6">
          <div>
            <h2 className="text-xl font-extrabold tracking-tight text-gray-900">
              Option 2: Text your scores.
            </h2>
          </div>
          {
            (userInfo && !userInfo.phone) && (
              <div className="rounded-md bg-red-100 p-4 mt-6">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">Phone number needed</h3>
                    <div className="mt-2 text-sm text-red-700">
                      <p>In order to save your scores via text, enter your phone number under your user settings so we know it's you. Your phone number will not be used for any other purpose.</p>
                    </div>
                    <div className="mt-4">
                      <div className="-mx-2 -my-1.5 flex">
                        <Link
                          to="/settings/user"
                          type="button"
                          className="bg-red-100 px-2 py-1.5 rounded-md text-sm font-medium text-red-800 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                        >
                          Add phone &rarr;
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          <div className="px-2 py-3 mt-4">
            <h4 className="uppercase text-xs font-medium text-gray-500 mb-1">Text your scores to this number:</h4>
            <span className="inline-flex items-center py-2 rounded-lg text-2xl font-bold text-gray-900">
              +1 (903) 560-9786
              <button
                onClick={() => { navigator.clipboard.writeText('+19035609786') }}
                className="bg-gray-200 ml-2 p-1 rounded hover:bg-gray-300"
              >
                <DuplicateIcon className="h-6 w-6 text-gray-500 hover:text-gray-700" />
              </button>
            </span>
          </div>
          <dl className="mt-12 grid grid-cols-1 gap-y-10 sm:mt-12 md:grid-cols-2 md:gap-x-6 lg:grid-cols-3">
            {textSteps.map((step) => (
              <div key={step.step}>
                <dt className="text-base font-medium text-gray-900">{step.question}</dt>
                <dd className="mt-3 text-sm text-gray-500">
                  <span>
                    {step.answer}
                  </span>
                  {
                    step.img
                      ? (
                        <img src={step.img} className="rounded-lg mt-4" alt={step.question} />
                      ) : null
                  }
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </section>
  )
}

export default Instructions;