import { SpeakerphoneIcon } from "@heroicons/react/outline";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../contexts";
import { trialDays } from "../settings";

const TrialEnding = () => {
  const { userInfo } = useContext(AppContext);
  const [loaded, setLoaded] = useState(false);
  const [daysLeft, setDaysLeft] = useState(null);

  useEffect(() => {
    if (userInfo && userInfo.plan !== "pro") {
      const now = new Date().getTime() / 1000;
      const diff = now - userInfo.createdAt.seconds;
      const diffDays = diff / 60 / 60 / 24;
      const daysRemaining = Math.ceil(trialDays - diffDays);
      setDaysLeft(daysRemaining);
      setLoaded(true);
    } else if (userInfo) {
      setLoaded(true);
    }
  }, [userInfo]);

  if (loaded && daysLeft && daysLeft > 0 && daysLeft < 9) {
    return (
      <div className="mb-6">
        <div className="p-2 rounded-lg bg-black shadow-lg sm:p-3">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="flex items-center">
              <span className="flex p-2 rounded-lg bg-gray-700">
                <SpeakerphoneIcon
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </span>
              <p className="ml-3 text-white">
                <span>
                  Your free trial of SMW Pro is ending in {daysLeft} days.
                </span>
              </p>
            </div>
            <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
              <Link
                to="/pro"
                className="text-white hover:text-primary-reg mr-2 text-sm"
              >
                Details &rarr;
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  } else return null;
};

export default TrialEnding;
