import { Fragment, useContext } from 'react'
import { Menu, Popover, Transition } from '@headlessui/react';
import { Link, useLocation } from 'react-router-dom';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { classNames } from '../../utils';
import { useFirebase } from 'react-redux-firebase';
import { connect } from 'react-redux';
import Logo from '../../assets/Logo.png';
import { AppContext } from '../../contexts';

const Navbar = ({ routes, profile }) => {
  const location = useLocation();
  const firebase = useFirebase();
  const { userInfo } = useContext(AppContext);

  const displayName = userInfo
    ? userInfo.username
      ? userInfo.username
      : `${userInfo.firstName} ${userInfo.lastName}`
    : null

  const userNavigation = [
    { name: 'Your Profile', href: `/settings/user` },
    { name: 'Sign out', action: () => firebase.auth().signOut() },
  ]

  return (
    <>
      {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
      <Popover
        as="header"
        className={({ open }) =>
          classNames(
            open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
            'bg-white shadow-sm lg:static lg:overflow-y-visible'
          )
        }
      >
        {({ open }) => (
          <>
            <div className="mx-auto px-4 sm:px-8 py-4">
              <div className="relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8">
                <div className="flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-2">
                  <div className="flex-shrink-0 flex items-center">
                    <Link to="/">
                      <img
                        className="block h-8 w-auto"
                        src={Logo}
                        alt="Save My Wordle Logo"
                      />
                    </Link>
                  </div>
                </div>
                <div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6">
                  <div className="flex items-center px-6 py-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
                    <div className="w-full">

                    </div>
                  </div>
                </div>
                <div className="flex items-center md:absolute md:right-0 md:inset-y-0 lg:hidden">
                  {/* Mobile menu button */}
                  <Popover.Button className="-mx-2 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-rose-500">
                    <span className="sr-only">Open menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Popover.Button>
                </div>
                <div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4">
                  {/* Profile dropdown */}
                  <Menu as="div" className="flex-shrink-0 relative ml-5">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button className="bg-white rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500">
                            <span className="sr-only">Open user menu</span>
                            {
                              profile && profile.profilePicUrl
                                ? (
                                  <span className="inline-flex items-center justify-center h-8 w-8 rounded-full overflow-hidden border border-gray-300">
                                    <img src={profile.profilePicUrl} alt="profile" />
                                  </span>
                                ) : (
                                  <span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-black">
                                    <span className="text-sm font-medium leading-none text-white">{displayName ? displayName.charAt(0).toUpperCase() : null}</span>
                                  </span>
                                )
                            }
                          </Menu.Button>
                        </div>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none"
                          >
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => {
                                  if (item.action) {
                                    return (
                                      <Link
                                        to="/"
                                        onClick={item.action}
                                        className={classNames(
                                          active ? 'bg-gray-100' : '',
                                          'block py-2 px-4 text-sm text-gray-700'
                                        )}
                                      >
                                        {item.name}
                                      </Link>
                                    )
                                  } else {
                                    return (
                                      <Link
                                        to={item.href}
                                        className={classNames(
                                          active ? 'bg-gray-100' : '',
                                          'block py-2 px-4 text-sm text-gray-700'
                                        )}
                                      >
                                        {item.name}
                                      </Link>
                                    )
                                  }
                                }}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
              </div>
            </div>

            <Popover.Panel as="nav" className="lg:hidden" aria-label="Global">
              <div className="max-w-3xl mx-auto px-2 pt-2 pb-3 space-y-1 sm:px-4">
                {routes.filter(route => route.display === 'main').map((item) => {
                  const currentRoute = location.pathname === item.path;
                  return (
                    <Popover.Button
                      key={item.name}
                      className={classNames(
                        currentRoute ? 'text-gray-900' : 'hover:bg-gray-50',
                        'block rounded-md py-2 px-3 text-base font-medium'
                      )}
                    >
                      <Link
                        to={item.path}
                        className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 flex items-center"
                      >
                        <item.icon className="flex-shrink-0 h-5 w-5 text-black" aria-hidden="true" />
                        <div className="ml-4">
                          <p className="text-base font-medium text-black">{item.name}</p>
                        </div>
                      </Link>
                    </Popover.Button>
                  )
                })}
              </div>
              <div className="border-t border-gray-200 pt-4 pb-3">
                <div className="max-w-3xl mx-auto px-4 flex items-center sm:px-6">
                  <div className="flex-shrink-0">
                    {profile && profile.profilePicUrl
                      ? (
                        <span className="inline-flex items-center justify-center h-10 w-10 rounded-full border border-gray-200 overflow-hidden">
                          <img src={profile.profilePicUrl} alt="profile" />
                        </span>
                      ) : (
                        <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
                          <span className="font-medium leading-none text-white">{displayName ? displayName.charAt(0).toUpperCase() : null}</span>
                        </span>
                      )
                    }
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">{displayName}</div>
                    <div className="text-sm font-medium text-gray-500">{profile ? profile.email : null}</div>
                  </div>
                </div>
                <div className="mt-3 max-w-3xl mx-auto px-2 space-y-1 sm:px-4">
                  {userNavigation.map((item) => {
                    if (item.action) {
                      return (
                        <Link
                          to=""
                          key={item.name}
                          onClick={item.action}
                          className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                        >
                          {item.name}
                        </Link>
                      )
                    } else {
                      return (
                        <Popover.Button
                          key={item.name}
                          className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                        >
                          <Link
                            to={item.href}

                          >
                            {item.name}
                          </Link>
                        </Popover.Button>
                      )
                    }
                  })}
                </div>
              </div>
            </Popover.Panel>
          </>
        )
        }
      </Popover >
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth
  }
}

export default connect(mapStateToProps)(Navbar);