import React, { useContext } from "react";
import { Switch } from "react-router-dom";
import SecureRoute from "../SecureRoute";
import Paywall from "../Paywall";
import AdminStats from "../../scenes/AdminStats";
import { AppContext } from "../../contexts";

const Main = ({ routes }) => {
  const { userId } = useContext(AppContext);

  const renderRoutes = () => {
    return routes
      .filter((route) => route.display === "main" || "none")
      .map((route, index) => {
        if (route.paywall) {
          return (
            <Paywall
              path={route.path}
              component={route.component}
              exact={route.exact}
              key={route.path}
            />
          );
        } else {
          return (
            <SecureRoute
              path={route.path}
              component={route.component}
              exact={route.exact}
              key={route.path}
            />
          );
        }
      });
  };

  return (
    <Switch>
      {userId === "GtkcVtQMCnWY0lOOGdTSjbuCuy02" ||
      "Ox4TzUaQn1T7qwGXAQtfV0FOhMi1" ? (
        <SecureRoute exact path="/admin-stats" component={AdminStats} />
      ) : null}
      {renderRoutes()}
    </Switch>
  );
};

export default Main;
