import React from 'react';
import { Link } from 'react-router-dom';

const medals = {
  1: '🥇',
  2: '🥈',
  3: '🥉'
}

const LastMonthLeaders = ({ leaders }) => {

  if (leaders.length && leaders[0].points > 0) {
    return (
      <div className="mt-4">
        <div className="sm:flex sm:items-center sm:justify-between">
          <h3 className="text-sm leading-6 font-medium text-gray-500">Last Month's Champion</h3>
          <div className="mt-3 sm:mt-0 sm:ml-4">
            {/* <Link
              className="text-sm text-primary-reg hover:text-primary-light"
            >
              See Past Months
            </Link> */}
          </div>
        </div>
        <div>
          <div className="mt-1 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {leaders.map((leader, index) => (
              <Link
                key={leader.id}
                to={`/player/${leader.id}`}
              >
                <div
                  className="bg-white py-5 px-4 sm:py-6 sm:px-6 shadow rounded-lg overflow-hidden"
                >
                  <div className="flex items-center space-x-2">
                    <div className="">
                      <span className="text-5xl">{medals[index + 1]}</span>
                    </div>
                    <div>
                      <p className="text-base font-medium text-gray-500 truncate">{leader.name}</p>
                      <p className="text-xl font-semibold text-gray-900">{leader.points}</p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    )
  } else return null
}

export default LastMonthLeaders;