import React from 'react';
import ReactDOM from 'react-dom';
import App from './scenes';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { config } from './services/firebase';
import { createFirestoreInstance } from 'redux-firestore';
import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/analytics';
import 'firebase/storage';
import createReduxStore from './store';
import './index.css';
import { HelmetProvider } from 'react-helmet-async';

export const rrfConfig = {
  userProfile: 'users',
  //attachAuthIsReady: true,
  useFirestoreForProfile: true
};

// Initialize firebase instance
firebase.initializeApp(config);

// Initialize other services on firebase instance
firebase.auth();
firebase.analytics();
firebase.storage();

if (process.env.REACT_APP_ENV === 'local') {
  // firebase.firestore().settings({ host: 'localhost:8080', ssl: false });
  firebase.functions().useFunctionsEmulator('http://localhost:5001');
} else {
  firebase.firestore().settings({});
  firebase.functions();
}

// Export authRef and provider
export const authRef = firebase.auth();
export const provider = new firebase.auth.GoogleAuthProvider();

const store = createReduxStore();

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
};

const Root = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <BrowserRouter>
            <HelmetProvider>
              <Route path='/' component={App} />
            </HelmetProvider>
          </BrowserRouter>
        </ReactReduxFirebaseProvider>
      </Provider>
    </React.StrictMode>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));