import React from 'react';
import { Header } from '../../ui';
import ScoreTable from './ScoreTable';

const ScoresHome = () => {
  return (
    <div>
      <Header
        title="My Scores"
        actions={[
          {
            title: 'Add Score',
            type: 'link',
            metadata: {
              linkTo: '/scores/submit'
            }
          }
        ]}
      />
      <ScoreTable />
    </div>
  )
}

export default ScoresHome;