import React, { useContext, useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { AppContext } from '../../../contexts';
import { CheckIcon, ExclamationCircleIcon } from '@heroicons/react/solid';
import { useFirestore } from 'react-redux-firebase';
import { Input, DialCodeSelect } from '../../../components/Forms';
import Loader from 'react-loader-spinner';
import { getCountryListMap } from 'country-flags-dial-code';



const PhoneSettings = () => {
  const { userInfo, userId, mixpanel } = useContext(AppContext);
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const [startingPhone, setStartingPhone] = useState(null);
  const [countryObj, setCountryObj] = useState(null);
  const firestore = useFirestore();
  const rawCountryList = getCountryListMap();
  const [countryList, setCountryList] = useState(null);

  useEffect(() => {
    let countryListSnap = [];
    Object.keys(rawCountryList).map(code => {
      const countryInfo = rawCountryList[code];
      return countryListSnap.push({
        id: countryInfo.code,
        value: countryInfo.dialCode,
        name: `${countryInfo.country} (${countryInfo.dialCode})`,
        flag: countryInfo.flag
      })
    })
    setCountryList(countryListSnap);
  }, [rawCountryList])

  useEffect(() => {
    if (userInfo && userInfo.phone && countryList && countryList.length) {
      //get last 9 digits of phone
      const phoneSnap = userInfo.phone.substr(userInfo.phone.length - 10);
      setStartingPhone(phoneSnap);
      //search country code
      if (userInfo.countryCode) {
        setCountryObj({
          id: userInfo.countryCode,
          value: rawCountryList[userInfo.countryCode].dialCode
        })
      } else {
        const dialCodeSnap = userInfo.phone.substring(0, userInfo.phone.length - 10);
        const dialCodeIdSnap = countryList.find(({ value }) => value === dialCodeSnap);
        setCountryObj({ id: dialCodeIdSnap.id === 'CA' ? 'US' : dialCodeIdSnap.id, value: dialCodeIdSnap.value });
      }
    }

  }, [userInfo, countryList, rawCountryList])

  const onSubmit = formValues => {
    setSubmitting(true);
    setStatus(null);

    const { dialCode, phone } = formValues;

    firestore
      .collection('users')
      .doc(userId)
      .update({
        phone: `${dialCode.value}${phone.replace(/[^\d]/g, "")}`,
        countryCode: dialCode.id
      })
      .then(() => {
        mixpanel.track('Updated phone settings', {
          distinct_id: userId
        })
        setSubmitting(false);
        setError(null);
        setStatus('saved');
      })
      .catch(err => {
        setSubmitting(false);
        setStatus('error');
        setError(err.message);
      })
  }

  const initialValues = {
    dialCode: countryObj
      ? countryObj : {
        id: 'US',
        value: '+1'
      },
    phone: startingPhone ? startingPhone : '',
  }

  const renderStatus = () => {
    if (status === 'saved') {
      return (
        <p className="text-primary-900">
          <span className="inline-flex items-center px-2 text-xs text-medium mt-2">
            <CheckIcon className="mr-1.5 h-4 w-4" />
            Saved
          </span>
        </p>
      )
    } else if (status === 'error') {
      return (
        <p className="text-red-500">
          <span className="inline-flex items-center px-2 text-xs text-medium">
            <ExclamationCircleIcon className="mr-1.5 h-4 w-4" />
            {error}
          </span>
        </p>
      )
    } else return null
  }

  const validate = values => {
    const errors = {}

    if (!values.phone) {
      errors.phone = 'Please input your mobile number'
    }
    if (values.phone) {
      const phoneValidated = values.phone.match(/[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/im);
      if (!phoneValidated) {
        errors.phone = 'Invalid phone number format'
      }
    }

    return errors;
  }

  return (
    <>
      <div className="mt-6 sm:pb-20">
        <div className="space-y-6">
          <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            <Form action="#" method="POST">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  <div className="md:grid md:grid-cols-3">
                    <div className="md:col-span-1 md:pr-6">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">Phone Number</h3>
                      <p className="mt-1 text-sm text-gray-500">Add or edit your phone number so you can text your scores to us instead of saving through the website. Don't worry, we won't spam you. You will save your scores by texting them to us, and this is how we know which texts come from you.</p>
                    </div>
                    <div className="mt-5 sm:mt-0 md:col-span-2">
                      <div className="md:grid md:grid-cols-4 md:gap-6 mt-4">
                        <div className='mt-4 md:mt-0 md:col-span-2'>
                          <Field
                            name="dialCode"
                            label="Country Dial Code"
                            component={DialCodeSelect}
                            options={countryList}
                          />
                        </div>
                        <div className='mt-4 md:mt-0 md:col-span-2'>
                          <Field
                            name='phone'
                            label='Mobile Number'
                            help="Please put the number you will be texting your scores from."
                            type='text'
                            component={Input}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <div className="md:mr-6">
                    {renderStatus()}
                  </div>
                  <button
                    type="reset"
                    className="btn-secondary mr-2 btn-small"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn-primary btn-small"
                    disabled={submitting}
                  >
                    {
                      submitting
                        ? <Loader type="TailSpin" height="18" width="18" color="#000" />
                        : 'Save'
                    }
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </>
  )
}

export default PhoneSettings;