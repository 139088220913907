import React from 'react';
import ScoreCountChart from './ScoreCountChart';
import EvaluationsChart from './EvaluationsChart';

const ProStats = ({ wordleNumber }) => {
  return (
    <div>
      <div className="sm:grid sm:grid-cols-2 sm:gap-x-5 mt-6">
        <div className="bg-white shadow rounded-lg">
          <ScoreCountChart wordleNumber={wordleNumber} />
        </div >
        <div className="bg-white shadow rounded-lg mt-6 sm:mt-0">
          <EvaluationsChart wordleNumber={wordleNumber} />
        </div >
      </div>
    </div>
  )
}

export default ProStats;