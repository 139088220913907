import React from 'react';
//import { Link } from 'react-router-dom';
import Logo from '../../../assets/Logo.png';

const pointsConfig = {
  '1': 10,
  '2': 7,
  '3': 5,
  '4': 3,
  '5': 2,
  '6': 1,
  'X': 0
}

const LeagueInstructions = () => {
  return (
    <section aria-labelledby="faq-heading" className="">
      <div className="">
        <div className="max-w-xl">
          <img src={Logo} className="h-14" alt="Save My Wordle Logo" />
          <h2 id="faq-heading" className="mt-4 text-2xl font-extrabold tracking-tight text-gray-900">
            Wordle Leagues
          </h2>
          <p className="mt-4 text-base text-gray-500">
            Create a Wordle league, earn points for your scores, and compete with friends!
          </p>
          {/* <div className="mt-2">
            <Link
              to="/leagues/instructions#scoring"
              className="text-base text-primary-dark font-semibold hover:text-primary-light"
            >
              Scoring
            </Link>
          </div> */}
        </div>

        <div className="mt-8">
          <div className="sm:grid sm:grid-cols-4 sm:space-x-10">
            <div className="sm:col-span-1 flex flex-col">
              <h2 className="text-xl font-semibold tracking-tight text-gray-900">
                Points
              </h2>
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 mt-2">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="py-3.5 text-center text-sm font-semibold text-gray-900">
                            Wordle Score
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                            Points
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {Object.keys(pointsConfig).map((item) => (
                          <tr key={item}>
                            <td className="whitespace-nowrap text-center py-4 text-sm font-medium text-gray-900">
                              {item}/6
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{pointsConfig[item]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-3">
              <h2 className="text-xl font-semibold tracking-tight text-gray-900">
                Monthly Comptetitions
              </h2>
              <p className="mt-4 text-sm text-gray-500">
                Currently, Wordle leagues show the scores on a month-to-month basis, meaning that scores will reset at the beginning of each month.
              </p>
              <p className="mt-4 text-sm text-gray-500">
                Upcoming plans for <strong className="font-semibold">Save My Wordle</strong> include features that allow you to select the time period for which you would like to compete, as well as view past winners. If you have suggestions or things you would like added to leagues, email
                <a href="mailto:support@savemywordle.com" className="ml-1 text-primary-dark hover:text-primary-light font-medium">support@savemywordle.com</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LeagueInstructions;