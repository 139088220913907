import React from 'react';
import { Route, Switch } from 'react-router';
import User from './User';
import SettingsMenu from './SettingsMenu';

const Settings = () => {
  return (
    <Switch>
      <Route exact path="/settings" component={SettingsMenu} />
      <Route exact path="/settings/user" component={User} />
    </Switch>
  )
}

export default Settings;