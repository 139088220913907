import React, { useEffect, useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import { classNames, convertWordleToDate } from '../../utils';


const ScoreTable = ({ userId, location }) => {
  const [scores, setScores] = useState(null);
  const firestore = useFirestore();

  // const convertToDate = (date) => {
  //   const options = { year: 'numeric', month: 'long', day: 'numeric' };
  //   const timestamp = new Date(date.seconds * 1000).toLocaleDateString("en-US", options);
  //   return timestamp;
  // } 


  useEffect(() => {
    const getScores = async () => {
      let scoresArray = []
      const scoresSnap = await firestore.collection('users').doc(userId).collection('scores').orderBy('wordleNumber', 'desc').get();
      scoresSnap.docs.forEach(doc => {
        scoresArray.push({ id: doc.id, ...doc.data() })
      })
      setScores(scoresArray)
    }

    getScores()
  }, [userId, firestore]);

  if (scores && scores.length > 0) {
    return (
      <div className="overflow-hidden mt-1 bg-white rounded-lg shadow">
        <ul className="">
          <li className="border-b border-gray-300 px-3">
            <div className="block">
              <div className="py-2">
                <div className="flex items-center justify-between">
                  <p className="text-xs font-medium text-gray-700 truncate uppercase">Score</p>
                  <div className="ml-2 flex-shrink-0 flex">
                    <p className="inline-flex text-xs text-gray-700 uppercase">
                      Date
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>
          {scores.map((score) => (
            <Link
              key={score.id}
              to={{
                pathname: `/player/${userId}/score/${score.id}`,
                state: { prevPath: location && location.pathname }
              }}
            >
              <li className="border-b border-gray-300 px-3 hover:bg-gray-50">
                <div className="block">
                  <div className="py-3">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <p className={classNames(
                          Number(score.score) < 4
                            ? 'bg-primary-light text-white'
                            : score.score === 'X'
                              ? 'bg-red-300 text-red-900'
                              : 'bg-gray-200 text-black',
                          "text-sm font-bold truncate rounded-lg py-1.5 px-3.5"
                        )}
                        >
                          {score.score}
                        </p>
                        <div>
                          <p className="ml-2 flex items-center text-sm text-black font-medium">
                            {score.score}/6
                          </p>
                          <p className="ml-2 flex items-center text-xs text-gray-500 font-medium">
                            Wordle #{score.wordleNumber}
                          </p>
                        </div>
                      </div>
                      <div className="flex-shrink-0 flex">
                        <p className="inline-flex text-xs text-gray-500">
                          {convertWordleToDate(score.wordleNumber, 'MMMM dd, yyyy')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </Link>
          ))}
        </ul>
      </div >
    )
  } else return (
    null
  )
}

export default ScoreTable;