import React, { useState, useEffect } from 'react';
import { ArrowSmLeftIcon, CalendarIcon } from '@heroicons/react/solid';
import { useFirestore } from 'react-redux-firebase';
import MainStats from './MainStats';
import ScoreTable from './ScoreTable';

const PlayerDetail = ({ match, history }) => {
  const [playerInfo, setPlayerInfo] = useState(null);
  const playerId = match.params.playerId;
  const firestore = useFirestore();
  const options = { year: 'numeric', month: 'long' };
  const timestamp = playerInfo && new Date(playerInfo.createdAt.seconds * 1000).toLocaleDateString("en-US", options);


  useEffect(() => {
    const getLeagueInfo = async () => {
      const playerSnap = await firestore.collection('users').doc(playerId).get();
      const player = playerSnap.data();
      setPlayerInfo(player);
    }
    if (!playerInfo) {
      getLeagueInfo()
    }
  }, [playerId, firestore, playerInfo]);

  const playerName = playerInfo && playerInfo.username
    ? playerInfo.username
    : playerInfo && playerInfo.firstName && playerInfo.lastName
      ? `${playerInfo.firstName} ${playerInfo.lastName}`
      : null;

  return (
    <div>
      <header className="">
        <div className="mx-auto xl:flex xl:items-center xl:justify-between">
          <div className="flex-1 min-w-0">
            <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate flex items-center">
              <ArrowSmLeftIcon
                onClick={history.goBack}
                className="h-10 w-10 bg-gray-200 hover:bg-gray-300 mr-2 rounded-full p-2"
              />
              {playerName}
            </h1>
            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                Joined {timestamp}
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="mt-6">
        <MainStats userId={playerId} />
      </div>
      <div className="mt-6">
        <h3 className="text-sm leading-6 font-medium text-gray-500">Scores History</h3>
        <ScoreTable userId={playerId} />
      </div>
    </div>
  )
}

export default PlayerDetail;