import React, { useState, useEffect } from 'react';
import { useFirebase } from 'react-redux-firebase';
import { Formik, Field, Form } from 'formik';
import {
  Input,
  // DialCodeSelect 
} from '../../components/Forms';
import {
  validateEmail,
  // capitalizeFirst 
} from '../../utils';
import Spinner from '../../components/Spinner';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Logo from '../../assets/Logo.png';
import { getCountryListMap } from 'country-flags-dial-code';
import qs from 'qs';
import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: false });


const Register = ({ history, auth, profile, location }) => {
  const [submitting, setSubmitting] = useState(false);
  const [loggedMixpanel, setLoggedMixpanel] = useState(false);
  const [authError, setAuthError] = useState(null);
  const firebase = useFirebase();
  const rawCountryList = getCountryListMap();
  const countryList = [];
  const redirect = location.search && location.search ? qs.parse(location.search, { ignoreQueryPrefix: true }).redirectUri : null;

  useEffect(() => {
    if (!loggedMixpanel) {
      mixpanel.track('View register page');
      setLoggedMixpanel(true);
    }
  }, [loggedMixpanel]);


  Object.keys(rawCountryList).map(code => {
    const countryInfo = rawCountryList[code];
    return countryList.push({
      id: countryInfo.code,
      value: countryInfo.dialCode,
      name: `${countryInfo.country} (${countryInfo.dialCode})`,
      flag: countryInfo.flag
    })
  })

  useEffect(() => {
    const redirectToHome = async () => {
      history.push('/')
    };
    const redirectToPath = async () => {
      history.push(redirect)
    };

    if (auth && profile && profile.isLoaded) {
      if (redirect) {
        redirectToPath()
      } else {
        redirectToHome();
      }
    } else return null;
  }, [auth, history, profile, redirect]);

  const onSubmit = async values => {
    setSubmitting(true);



    const createNewUser = ({
      email,
      password,
      // firstName,
      // lastName,
      // initials,
      createdAt,
      //phone,
      username
    }) => {
      firebase.createUser(
        { email, password },
        {
          email,
          // firstName,
          // lastName,
          // initials,
          createdAt,
          //phone,
          username
        }
      ).then(() => {
        setAuthError(null);
      }).catch(err => {
        setSubmitting(false);
        setAuthError(err.message);
        window.scrollTo(0, 0)
      })
    }

    const {
      email,
      password,
      // firstName, 
      // lastName, 
      // phone, 
      //dialCode, 
      username
    } = values;

    const timestamp = new Date();

    createNewUser({
      email,
      password,
      // firstName: firstName ? capitalizeFirst(firstName.toLowerCase()) : null,
      // lastName: lastName ? capitalizeFirst(lastName.toLowerCase()) : null,
      // initials: firstName && lastName ? firstName[0].toUpperCase() + lastName[0].toUpperCase() : username[0],
      createdAt: timestamp,
      // phone: `${dialCode.value}${phone.replace(/[^\d]/g, "")}`,
      username
    })
  };

  const renderAuthError = () => {
    if (authError) {
      return (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              {/* <!-- Heroicon name: check-circle --> */}
              <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-red-800">
                {authError}
              </p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  onClick={() => setAuthError(null)}
                  className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                >
                  <span className="sr-only">Dismiss</span>
                  {/* <!-- Heroicon name: x --> */}
                  <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const validate = values => {
    const errors = {}

    if (!values.username) {
      errors.username = 'Please input a username'
    }
    if (!values.email) {
      errors.email = 'Please input your email'
    }
    // if (!values.phone) {
    //   errors.phone = 'Please input your mobile number'
    // }
    // if (values.phone) {
    //   const phoneValidated = values.phone.match(/[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/im);
    //   if (!phoneValidated) {
    //     errors.phone = 'Invalid phone number format'
    //   }
    // }
    if (!validateEmail(values.email)) {
      errors.email = 'Please input a valid email'
    }
    if (!values.password) {
      errors.password = 'Please input a password'
    }
    if (!values.passwordConfirm) {
      errors.passwordConfirm = 'Please confirm your password'
    }
    if (values.passwordConfirm !== values.password) {
      errors.passwordConfirm = 'Your password does not match'
    }
    return errors;
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-16 w-auto" src={Logo} alt="Save My Wordle" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Let's Get Started!
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md px-2">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {renderAuthError()}
          <Formik
            initialValues={
              {
                // firstName: '',
                // lastName: '',
                username: '',
                email: '',
                // dialCode: {
                //   id: 'US',
                //   value: '+1'
                // },
                // phone: '',
                password: '',
                passwordConfirm: ''
              }
            }
            onSubmit={onSubmit}
            validate={validate}
          >
            <Form className="space-y-6" action="#" method="POST">
              <div className='mt-6'>
                <Field
                  name='username'
                  label='Enter a username'
                  help="This username will be your display name for any leagues that you join."
                  type='text'
                  component={Input}
                />
              </div>
              {/* <div className='mt-6'>
                <Field
                  name='firstName'
                  label='First Name'
                  type='text'
                  component={Input}
                />
              </div>
              <div className='mt-6'>
                <Field
                  name='lastName'
                  label='Last Name'
                  type='text'
                  component={Input}
                />
              </div> */}
              <div className='mt-6'>
                <Field
                  name='email'
                  label='Email'
                  type='email'
                  component={Input}
                />
              </div>
              {/* <div className="mt-6">
                <Field
                  name="dialCode"
                  label="Country Dial Code"
                  component={DialCodeSelect}
                  options={countryList}
                />
              </div>
              <div className='mt-6'>
                <Field
                  name='phone'
                  label='Mobile Number'
                  help="Don't worry, we won't spam you. You will save your scores by texting them to us, and this is how we know which texts come from you. Please put the number you will be texting your scores from."
                  type='text'
                  component={Input}
                />
              </div> */}
              <div className='mt-6'>
                <Field
                  name='password'
                  label='Password'
                  type='password'
                  component={Input}
                />
              </div>
              <div className='mt-6'>
                <Field
                  name='passwordConfirm'
                  label='Confirm Password'
                  type='password'
                  component={Input}
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full btn-primary flex justify-center"
                  disabled={submitting}
                >
                  {submitting
                    ? <Spinner height={20} color="#FFFFFF" />
                    : 'Register'
                  }
                </button>
              </div>
            </Form>
          </Formik>
          <div className="relative flex justify-between text-sm">
            <Link to="/login" className="text-primary-500 hover:text-primary-700 font-medium text-sm mt-6">
              Back To Login
            </Link>
            <a
              href="https://savemywordle.com/privacy"
              target="_blank"
              rel="noreferrer"
              className="text-primary-500 hover:text-primary-700 font-medium text-sm mt-6"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth.uid,
    profile: state.firebase.profile,
  };
};

export default withRouter(connect(mapStateToProps)(Register));