import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../contexts';
import SecureRoute from '../SecureRoute';
import { trialDays } from '../../settings';
import PaywallScene from './PaywallScene';

const Paywall = (props) => {
  const { userInfo } = useContext(AppContext);
  const [paywall, setPaywall] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (userInfo && userInfo.plan !== 'pro') {
      const now = new Date().getTime() / 1000
      const diff = now - userInfo.createdAt.seconds;
      const diffDays = diff / 60 / 60 / 24
      if (diffDays > trialDays) {
        setPaywall(true);
      }
      setLoaded(true);
    } else if (userInfo) {
      setLoaded(true);
    }
  }, [userInfo])


  if (!paywall && loaded) {
    return <SecureRoute {...props} />
  } else if (loaded) {
    return <PaywallScene />
  } else return null
}

export default Paywall;