import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppContext } from '../contexts';
import Login from './Auth/Login';
import AppMain from '../components/AppMain';
import Register from './Auth/Register';
import ForgotPassword from './Auth/ForgotPassword';
import ResetPassword from './Auth/ResetPassword';
import mixpanel from 'mixpanel-browser';
import { useFirestore } from 'react-redux-firebase';
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: false });

const App = ({ auth, profile, history }) => {
  const [mixpanelInit, setMixpanelInit] = useState(false);
  const [mixpanelSubmitting, setMixpanelSubmitting] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const displayName = userInfo && userInfo.username
    ? userInfo.username
    : userInfo && userInfo.firstName && userInfo.lastName
      ? `${userInfo.firstName} ${userInfo.lastName}`
      : null;
  const userLoaded = auth.isLoaded;
  const authenticated = auth.uid;
  const userId = auth.uid;
  const { location } = history;
  const firestore = useFirestore();


  useEffect(() => {
    const getUserInfo = async () => {
      const userSnap = await firestore.collection('users').doc(userId).get();
      const userData = userSnap.data();
      setUserInfo(userData);
    }
    if (!userInfo && userId) {
      getUserInfo()
    }
  }, [userId, firestore, userInfo])

  useEffect(() => {
    if (!mixpanelInit && userId && !mixpanelSubmitting) {
      setMixpanelSubmitting(true);
      mixpanel.identify(userId);
      mixpanel.track('App view', {
        distinct_id: userId
      });
      setMixpanelInit(true);
      setMixpanelSubmitting(false);
    }
  }, [userId, mixpanelInit, mixpanelSubmitting]);

  return (
    <div>
      <Switch>
        <Route
          exact
          path="/login"
          component={Login}
        />
        <Route
          exact
          path="/forgot-password"
          component={ForgotPassword}
        />
        <Route
          exact
          path="/reset-password"
          component={ResetPassword}
        />
        <Route
          exact
          path="/register"
          component={Register}
        />
        <Route
          path="/"
          render={props => {
            if (!authenticated && userLoaded) {
              return (
                <Redirect
                  to={`/login?redirectUri=${location.pathname}${location.search}`}
                />
              );
            } else if (authenticated && userLoaded) {
              return (
                <AppContext.Provider
                  value={{
                    userId,
                    userInfo,
                    mixpanel,
                    displayName
                  }}
                >
                  <AppMain {...props} />
                </AppContext.Provider>
              )
            }
          }}
        />
      </Switch>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth
  }
}

export default connect(mapStateToProps)(App);
