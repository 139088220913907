import React, { useState, useEffect, useContext } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { AppContext } from '../../../contexts';
import { classNames } from '../../../utils';

const Tile = ({ percent, showAlways }) => {
  const calcColor = (n) => {
    if (n === 0) {
      return 0
    }
    else if (n > 0) {
      return Math.ceil(n / 10.0) * 10;
    }
  }

  const color = calcColor(percent * 100);

  return (
    <div
      className={classNames(
        color === 10
          ? 'bg-primary-50'
          : color === 20
            ? 'bg-primary-100'
            : color === 30
              ? 'bg-primary-200'
              : color === 40
                ? 'bg-primary-300'
                : color === 50
                  ? 'bg-primary-400'
                  : color === 60
                    ? 'bg-primary-500'
                    : color === 70
                      ? 'bg-primary-600'
                      : color === 80
                        ? 'bg-primary-700'
                        : color === 90
                          ? 'bg-primary-700'
                          : color === 100
                            ? 'bg-primary-900'
                            : 'bg-white'
        , 'w-1/5 border rounded h-8 sm:h-9.5 flex justify-center items-center group'
      )}
    >
      <span
        className={classNames(
          color > 50
            ? 'text-white'
            : color > 10
              ? 'text-black'
              : 'text-gray-500'
          , 'text-xs font-medium'
        )}
      >
        <span className={classNames(
          showAlways ? 'block' : 'hidden group-hover:block'
          , ''
        )}>
          {(percent * 100).toFixed(0)}%
        </span>
      </span>
    </div>
  )
}

const EvaluationsChart = ({ wordleNumber }) => {
  const [evaluations, setEvaluations] = useState(null);
  const firestore = useFirestore();
  const { userId } = useContext(AppContext);

  useEffect(() => {
    const getEvaluations = async () => {
      const evalsSnap = await firestore.collection('worldStats').doc('evaluationStats').collection('daily').doc(`${wordleNumber}`).get();
      const exists = evalsSnap.exists;
      if (exists) {
        const evals = await evalsSnap.data();
        setEvaluations(evals);
      }
    }
    if (!evaluations) {
      getEvaluations();
    }
  }, [firestore, wordleNumber, evaluations]);

  return (
    <div className="py-5 px-4 sm:py-6 sm:px-6">
      <div>
        <p className="text-sm font-medium text-gray-500 truncate">Today's Guess Distribution</p>
      </div>
      <div className="pt-4 h-64">
        {evaluations
          ? (
            <div className="space-y-1">
              {Object.keys(evaluations).map(row => (
                <div key={row} className="flex gap-x-1">
                  {Object.keys(evaluations[row]).map(col => {
                    const evalPercent = (evaluations[row][col].correct / evaluations[row][col].total).toFixed(2);
                    return (
                      <Tile key={`${row}-${col}`} percent={evalPercent} showAlways={userId === 'GtkcVtQMCnWY0lOOGdTSjbuCuy02'} />
                    )
                  })}
                </div>
              ))}
            </div>
          ) : null
        }
      </div>
    </div>
  )
}

export default EvaluationsChart;