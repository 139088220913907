import React, { useState, useContext, useEffect } from "react";
import { useFirestore, useFirebase } from "react-redux-firebase";
import { AppContext } from "../../contexts";
import { isEmpty } from "lodash";
import NewUser from "./NewUser";
import HeaderCharts from "./HeaderCharts";
import PersonalStats from "./PersonalStats.js";
import ProStats from "./ProStats.js";
import TrialEnding from "../../components/TrialEnding";

const Dashboard = () => {
  const [noGames, setNoGames] = useState(false);
  const [wordleNumber, setWordleNumber] = useState(null);
  const [freeStats, setFreeStats] = useState(null);
  //const [userTotalWordles, setUserTotalWordles] = useState(null);
  const [worldAverage, setWorldAverage] = useState(null);
  const [todaysWordle, setTodaysWordle] = useState(null);
  const { userInfo, userId } = useContext(AppContext);
  const firestore = useFirestore();
  const firebase = useFirebase();
  const d = new Date();
  const month = d.getMonth();
  const year = d.getFullYear();
  const day = d.getDate();

  useEffect(() => {
    const getWordleNum = async () => {
      const convertDateToWordle = firebase
        .functions()
        .httpsCallable("utils-convertDateToWordle");
      try {
        const res = await convertDateToWordle({
          date: `${year}-${month + 1}-${day}`,
        });
        setWordleNumber(res.data);
      } catch (e) {
        console.log(e);
      }
    };
    getWordleNum();
  }, [year, month, day, firebase]);

  useEffect(() => {
    const getTodaysWordle = async () => {
      const totalSnap = await firestore
        .collection("users")
        .doc(userId)
        .collection("statistics")
        .doc("dailyScores")
        .get();
      const dailyScores = totalSnap.data();

      const today = `${month < 9 ? `0${month + 1}` : month + 1}-${
        day < 10 ? `0${day}` : day
      }-${year}`;
      const todaysScore = dailyScores ? dailyScores[today] : null;
      setTodaysWordle(todaysScore);
    };

    const getUserTotals = async () => {
      let freeStatsSnap = {};

      //get totals
      const totalSnap = await firestore
        .collection("users")
        .doc(userId)
        .collection("statistics")
        .doc("totalResults")
        .get();
      const total = totalSnap.data();
      if (!isEmpty(total) || (total && total.gamesPlayed > 0)) {
        freeStatsSnap.userTotalWordles = total.gamesPlayed;
        freeStatsSnap.userSuccessRate =
          ((total.wins / total.gamesPlayed) * 100).toFixed(0) + "%";
      } else {
        setNoGames(true);
        freeStatsSnap.userTotalWordles = "0";
        freeStatsSnap.userSuccessRate = "0%";
      }

      //get averages
      const averageSnap = await firestore
        .collection("users")
        .doc(userId)
        .collection("statistics")
        .doc("totalAverages")
        .get();
      const averages = averageSnap.data();

      if (!isEmpty(averages)) {
        freeStatsSnap.userAverage = averages.avg;
      } else {
        freeStatsSnap.userAverage = "0";
      }

      //save to state
      setFreeStats(freeStatsSnap);
    };

    const getWorldScores = async () => {
      const scoresSnap = await firestore
        .collection("worldStats")
        .doc("scoreCounts")
        .collection("daily")
        .doc(`${wordleNumber}`)
        .get();
      const exists = scoresSnap.exists;
      if (exists) {
        const { gamesPlayed, scores } = await scoresSnap.data();

        let totalScoresCalc = 0;

        Object.keys(scores).map((score) => {
          if (score !== "X") {
            return (totalScoresCalc = totalScoresCalc + score * scores[score]);
          } else return null;
        });

        const avgScore = totalScoresCalc / gamesPlayed;

        setWorldAverage(avgScore.toFixed(2));
      } else {
        setWorldAverage("no data");
      }
    };
    getTodaysWordle();
    getUserTotals();
    getWorldScores();
  }, [userId, userInfo, day, firestore, month, wordleNumber, year]);

  const welcomeText = noGames ? "Hello" : "Welcome Back";

  const name =
    userInfo && userInfo.username
      ? userInfo.username
      : userInfo && userInfo.firstName
      ? `${userInfo.firstName}`
      : null;

  return (
    <div>
      <div>
        <TrialEnding />
      </div>
      <div className="grid grid-cols-4 flex items-center border-b border-gray-200 pb-4">
        <div className="col-span-4 sm:col-span-2">
          <h1 className="ml-3 text-3xl font-bold leading-7 text-gray-900 sm:leading-9">
            {welcomeText}
            {`${name ? `, ${name}` : ""}!`} 👋
          </h1>
        </div>
        <div className="mt-4 sm:mt-0 col-span-4 sm:col-span-2">
          <HeaderCharts
            todaysWordle={todaysWordle}
            worldAverage={worldAverage}
          />
        </div>
      </div>
      {noGames && (
        <div className="pt-3 sm:pt-4">
          <NewUser />
        </div>
      )}
      <div>
        {!noGames && (
          <div className="mt-6">
            <h3 className="uppercase text-xs text-gray-500 font-semibold tracking-wide">
              Your Statistics
            </h3>
            <div className="mt-4">
              <PersonalStats
                totalWordles={freeStats && freeStats.userTotalWordles}
                successRate={freeStats && freeStats.userSuccessRate}
                average={freeStats && freeStats.userAverage}
                wordleNumber={wordleNumber}
              />
            </div>
          </div>
        )}
        <div className="mt-6">
          <h3 className="uppercase text-xs text-gray-500 font-semibold tracking-wide">
            World Statistics
          </h3>
          <div className="mt-4">
            <ProStats wordleNumber={wordleNumber} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
