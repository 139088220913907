import React, { useContext, useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { AppContext } from '../../../contexts';
import { useFirestore } from 'react-redux-firebase';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false
      },
      ticks: {
        display: false
      },
      gridLines: {
        drawBorder: false,
      },
    },
    y: {
      grid: {
        display: false,
        drawBorder: false
      },
      ticks: {
        display: false
      },
      gridLines: {
        drawBorder: false,
      },
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    point: {
      radius: 0
    }
  },
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: false,
    }
  }
}

const PlayerAverage = () => {
  const [playerAverages, setPlayerAverages] = useState(null);
  const { userId } = useContext(AppContext);
  const firestore = useFirestore();

  const data = {
    datasets: [
      {
        label: 'Average Score',
        data: playerAverages ? playerAverages : [],
        borderColor: '#538f4f',
        backgroundColor: '#538f4f',
        tension: .5,
        pointHitRadius: 200
      }
    ],
  };

  useEffect(() => {
    const getAverages = async () => {
      const averagesSnap = await firestore.collection('users').doc(userId).collection('statistics').doc('runningAverages').get();
      const averagesData = averagesSnap.data();
      let averagesArray = [];
      if (averagesData) {
        Object.keys(averagesData).length && Object.keys(averagesData).map(day => {
          const dayData = averagesData[day];
          return averagesArray.push({
            x: day,
            y: dayData.avg
          })
        })
        const sortedAvgs = averagesArray.sort((a, b) => {
          const aDateArray = a.x.split("-");
          const bDateArray = b.x.split("-");
          const aDate = new Date(aDateArray[2], aDateArray[0] - 1, aDateArray[1]);
          const bDate = new Date(bDateArray[2], bDateArray[0] - 1, bDateArray[1]);

          if (aDate === bDate) {
            return 0
          } else if (aDate > bDate) {
            return 1
          } else return -1
        })
        setPlayerAverages(sortedAvgs);
      }
    }
    if (!playerAverages) {
      getAverages()
    }
  }, [userId, firestore, playerAverages]);

  return (
    <div className="pt-6 px-2 h-56 sm:h-72">
      <Line options={options} data={data} />
    </div>
  )
}

export default PlayerAverage;

