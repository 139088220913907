import React from 'react';
import Screenshot from '../../assets/Screenshot.png';
import { Link } from 'react-router-dom';

const NewUser = () => {
  return (
    <div className="bg-primary-100 rounded-lg shadow overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
      <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
        <div className="lg:self-center">
          <h2 className="text-3xl font-extrabold text-black sm:text-4xl">
            <span className="block text-lg font-medium text-primary-900">Welcome to Save My Wordle</span>
            <span className="block">Ready to dive in?</span>
          </h2>
          <p className="mt-4 text-base leading-6 text-gray-500">
            Saving scores is easy. Click below to view instructions on how to get started.
          </p>
          <Link
            to='/instructions'
            className="mt-8 bg-black border border-transparent rounded-md shadow px-3 py-2 inline-flex items-center text-sm font-medium text-white hover:bg-primary-900"
          >
            Instructions
          </Link>
        </div>
      </div>
      <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
        <img
          className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-4 lg:translate-y-16"
          src={Screenshot}
          alt="Save My Wordle Dashboard Screenshot"
        />
      </div>
    </div>
  )
}

export default NewUser;