import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { withFirebase } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Input } from '../../components/Forms';
import Spinner from '../../components/Spinner';
import Logo from '../../assets/Logo.png';
import qs from 'qs';


const Login = ({ firebase, auth, history, location, profile }) => {
    const [authError, setAuthError] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const redirect = location.search && location.search ? qs.parse(location.search, { ignoreQueryPrefix: true }).redirectUri : null;

    useEffect(() => {
        const redirectToHome = async () => {
            history.push('/')
        };
        const redirectToPath = async () => {
            history.push(redirect)
        };

        if (auth && profile && profile.isLoaded) {
            if (redirect) {
                redirectToPath()
            } else {
                redirectToHome();
            }
        } else return null;
    }, [auth, history, profile, redirect]);

    const onSubmit = async values => {
        setSubmitting(true);
        const { email, password } = values;
        await firebase
            .login({
                email,
                password
            })
            .catch(err => {
                setSubmitting(false);
                setAuthError(err.message);
            });
    };

    const validate = values => {
        const errors = {};
        if (!values.email) {
            errors.email = 'Please enter an email address';
        }
        if (!values.password) {
            errors.password = 'Please enter a password';
        }
        return errors;
    };

    const renderAuthError = () => {
        if (authError) {
            return (
                <div className="rounded-md bg-red-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            {/* <!-- Heroicon name: check-circle --> */}
                            <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                            </svg>
                        </div>
                        <div className="ml-3">
                            <p className="text-sm font-medium text-red-800">
                                {authError}
                            </p>
                        </div>
                        <div className="ml-auto pl-3">
                            <div className="-mx-1.5 -my-1.5">
                                <button
                                    onClick={() => setAuthError(null)}
                                    className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                                >
                                    <span className="sr-only">Dismiss</span>
                                    {/* <!-- Heroicon name: x --> */}
                                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };

    return (
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img className="mx-auto h-16 w-auto" src={Logo} alt="Save My Wordle" />
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md px-2">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <div>
                        <h2 className="text-center text-xl font-bold text-gray-900">
                            Log In
                        </h2>
                    </div>
                    {renderAuthError()}
                    <Formik
                        initialValues={{ email: '', password: '' }}
                        onSubmit={onSubmit}
                        validate={validate}
                    >
                        <Form className="space-y-6" action="#" method="POST">
                            <div className='mt-6'>
                                <Field
                                    name='email'
                                    label='Email'
                                    type='email'
                                    component={Input}
                                />
                            </div>
                            <div className='mt-6'>
                                <Field
                                    name='password'
                                    label='Password'
                                    type='password'
                                    component={Input}
                                />
                            </div>

                            <div className="flex items-center justify-between">
                                <div className="text-sm">
                                    <Link to="/forgot-password" className="font-medium text-primary-600 hover:text-primary-500">
                                        Forgot your password?
                                    </Link>
                                </div>
                            </div>

                            <div>
                                <button type="submit" className="w-full btn-primary flex justify-center" disabled={submitting}>
                                    {submitting
                                        ? <Spinner height={20} color="#FFFFFF" />
                                        : 'Log In'
                                    }
                                </button>
                            </div>
                        </Form>
                    </Formik>

                    <div className="mt-6">
                        <div className="relative">
                            <div className="absolute inset-0 flex items-center">
                                <div className="w-full border-t border-gray-300"></div>
                            </div>
                            <div className="relative flex justify-center text-sm">
                                <span className="px-2 bg-white text-gray-500">
                                    Don't have an account?
                                </span>
                            </div>
                        </div>

                        <div className="mt-6">
                            <div>
                                <Link
                                    to={`/register${redirect ? location.search : ''}`}
                                    className="btn-primary block">
                                    Sign Up For Free
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        auth: state.firebase.auth.uid,
        profile: state.firebase.profile,
    };
};

export default withRouter(connect(mapStateToProps)(withFirebase(Login)));