import React from 'react';
import _ from 'lodash';
import { classNames } from '../../utils';

export const TextArea = ({ field, name, form, label, errors, help, rows, ...props }) => {
  const renderLabel = () => {
    if (label) {
      return (
        <label
          htmlFor={label}
          className='block text-sm font-medium leading-5 text-gray-700'
        >
          {label}
        </label>
      );
    }
  };

  const renderError = () => {
    if (form.errors && _.get(form.errors, field.name) && _.get(form.touched, field.name)) {
      return (
        <p className='mt-2 text-sm text-red-600' id='email-error'>
          {_.get(form.errors, field.name)}
        </p>
      );
    }
  };

  const renderHelp = () => {
    if (help) {
      return (
        <p className="mt-2 text-sm text-gray-500">{help}</p>
      )
    }
  }

  return (
    <>
      {renderLabel()}
      <div className="mt-1">
        <textarea
          id={label}
          {...field}
          {...props}
          rows={rows ? rows : 3}
          className={
            classNames(
              form.errors && _.get(form.errors, field.name) && _.get(form.touched, field.name)
                ? 'border-red-300' : 'border-gray-300',
              'px-3 py-2 focus:outline-none focus:border-gray-500 block w-full sm:text-sm border rounded-md resize-none'
            )
          }
        />
      </div>
      {renderHelp()}
      {renderError()}
    </>
  );
};

TextArea.defaultProps = {
  type: 'text'
};
