import { Formik, Form, Field } from 'formik';
import { Input } from '../../components/Forms';
import React, { useContext, useState } from 'react';
import { Header } from '../../ui/Header';
import Spinner from '../../components/Spinner';
import { useFirestore } from 'react-redux-firebase';
import { AppContext } from '../../contexts';

const LeagueCreate = ({ history }) => {
  const [submitting, setSubmitting] = useState(false);
  const firestore = useFirestore();
  const { userId } = useContext(AppContext);

  const onSubmit = async values => {
    setSubmitting(true);
    const docRef = await firestore.collection('leagues').add({
      name: values.name,
      description: values.description,
      managers: [userId],
      members: [userId],
      createdBy: userId,
      createdAt: new Date(),
      numMembers: 1
    });
    await firestore.collection('users').doc(userId).update({
      leagues: firestore.FieldValue.arrayUnion(docRef.id)
    })
    history.push('/leagues');
  }

  return (
    <div>
      <Header title="Create A League" />
      <div className="mt-6 border-t border-gray-300 pt-6">
        <Formik
          initialValues={{
            name: '',
            description: ''
          }}
          onSubmit={onSubmit}
        >
          <Form>
            <div>
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-base font-medium leading-6 text-black">League Information</h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Configure the information and settings for your league. This will be visible to your league members when they view and join your league.
                    </p>

                  </div>
                </div>
                <div className="mt-6 md:mt-0 md:col-span-2">
                  <div className="sm:overflow-hidden">
                    <div className="px-4 space-y-6">
                      <div className="grid grid-cols-3 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <Field
                            name="name"
                            label="League Name"
                            type="text"
                            component={Input}
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <Field
                            name="description"
                            label="Description"
                            type="text"
                            component={Input}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-10">
              <div className="flex justify-between">
                <button
                  onClick={() => history.goBack()} type="button"
                  disabled={submitting}
                  className="bg-gray-200 hover:bg-gray-300 px-3.5 py-1.5 font-semibold rounded-lg text-gray-900"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={submitting}
                  className="bg-black hover:bg-primary-light px-3.5 py-1.5 font-semibold rounded-lg text-sm text-white"
                >
                  {submitting
                    ? <Spinner color="#FFFFFF" />
                    : 'Create League'
                  }
                </button>
              </div>
            </div>
          </Form>
        </ Formik>
      </div>
    </div>
  )
}

export default LeagueCreate;