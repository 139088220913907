import React, { useContext, useEffect, useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { AppContext } from '../../../contexts';

const Bar = ({ label, count, maxScore }) => {
  const maxHeight = 54;
  const n = (count / maxScore) * maxHeight;
  const calcHeight = (n) => {
    if (n === 0) {
      return .5
    }
    else if (n > 0) {
      return Math.ceil(n / 4.0) * 4;
    }
  }

  const height = 'h-' + calcHeight(n);

  return (
    <div className="relative flex flex-col items-center flex-grow pb-5">
      <span className="absolute top-0 -mt-6 text-xs font-medium text-gray-500">{count}</span>
      <div className={`relative flex justify-center w-full bg-primary-reg ${height} rounded-t-lg`}></div>
      <span className="absolute bottom-0 text-xs font-bold text-black">{label}</span>
    </div>
  )
}

const PersonalScoreCounts = () => {
  const [scoreCounts, setScoreCounts] = useState(null);
  const [barConfig, setBarConfig] = useState(null);
  const { userId } = useContext(AppContext);
  const firestore = useFirestore();

  useEffect(() => {
    const getScores = async () => {
      const scoresSnap = await firestore.collection('users').doc(userId).collection('statistics').doc('totalScoreCounts').get();
      const exists = scoresSnap.exists;
      if (exists) {
        const scores = await scoresSnap.data();

        setScoreCounts(scores);
      }
    }
    getScores();
  }, [firestore, userId]);

  useEffect(() => {
    let config = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      X: 0
    }

    const newConfig = { maxScore: 0, scores: {} }

    if (scoreCounts) {
      Object.keys(config).map(item => {
        if (scoreCounts[item]) {
          if (scoreCounts[item] > newConfig.maxScore) {
            newConfig.maxScore = scoreCounts[item];
          }
          return newConfig.scores[item] = scoreCounts[item];
        } else {
          return newConfig.scores[item] = 0
        }
      })
      setBarConfig(newConfig);
    }

  }, [scoreCounts]);

  return (
    <div className="">
      <div>
        <p className="text-sm font-medium text-gray-500 truncate">Guess Distribution</p>
        {/* {
          pro
            ? average
              ? (
                <span className="text-black font-semibold text-2xl">
                  {average}
                </span>
              ) : (
                <span className="text-gray-500 font-normal italic text-sm">
                  Waiting on data...
                </span>
              )
            : null
        } */}
      </div>
      <div
        className="h-64 pt-4"
      >
        <div className="flex flex-col items-center w-full">
          <div className="flex items-end flex-grow w-full mt-2 space-x-2 sm:space-x-3">
            {barConfig && Object.keys(barConfig.scores).map(item => (
              <Bar key={item} count={barConfig.scores[item]} label={item} maxScore={barConfig.maxScore} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PersonalScoreCounts;