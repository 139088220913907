import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../contexts';
import Screenshot from '../../assets/Screenshot2.png';
import ProLogo from '../../assets/ProLogo.png';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { useFirebase } from 'react-redux-firebase';
import Spinner from '../Spinner';
import { Link } from 'react-router-dom';

const includedFeatures = [
  'Personal stats & averages',
  'Compare vs. world stats',
  'Daily breakdowns of score difficulty',
  "View league members' scores",
]

const Paywall = (props) => {
  const { userInfo, userId, mixpanel } = useContext(AppContext);
  const [loggedMixpanel, setLoggedMixpanel] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const firebase = useFirebase();

  useEffect(() => {
    if (!loggedMixpanel && userId) {
      mixpanel.track('View Paywall', {
        distinct_id: userId
      });
      setLoggedMixpanel(true);
    }
  }, [loggedMixpanel, mixpanel, userId]);

  const signUp = async ({ plan, type }) => {
    setSubmitting(true);
    mixpanel.track('Checkout', {
      distinct_id: userId
    });
    const createCheckoutSession = firebase.functions().httpsCallable('stripe-createCheckoutSession');
    const { data } = await createCheckoutSession({ userId, email: userInfo.email, plan, type });
    return window.location.href = data
  }

  return (
    <div>
      <div className="rounded-md py-4 px-1">
        <div className="flex">
          <div className="flex-1 md:flex md:justify-between">
            <p className="text-sm text-black">Why these changes?</p>
            <p className="mt-3 text-sm md:mt-0 md:ml-6">
              <Link to="/letter" className="whitespace-nowrap font-medium text-black hover:text-primary-reg">
                A letter from our creator <span aria-hidden="true">&rarr;</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white rounded-lg shadow overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
        <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
          <div className="lg:self-center">
            <h2 className="text-3xl font-extrabold text-black sm:text-3xl">
              <img src={ProLogo} alt="logo" className="h-12 mb-4 bg-primary-50 px-6 py-2 rounded-full" />
              <span className="block">Your free trial has ended</span>
            </h2>
            <p className="mt-4 text-base leading-6 text-gray-700">
              Join for just $1.99/month to keep tracking your scores and stats.
            </p>
            <button
              onClick={() => signUp({ plan: process.env.REACT_APP_STRIPE_PLAN, type: 'subscription' })}
              className="mt-8 bg-black border border-transparent rounded-md shadow px-3 py-2 inline-flex items-center text-sm font-medium text-white hover:bg-primary-900"
            >
              {
                submitting ?
                  <Spinner color="#42723f" />
                  : 'Join for just $1.99/month'
              }
            </button>
          </div>
        </div>
        <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
          <img
            className="shadow transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-4 lg:translate-y-16"
            src={Screenshot}
            alt="Save My Wordle Dashboard Screenshot"
          />
        </div>
      </div>
      <div className="mt-5 max-w-lg mx-auto rounded-lg shadow overflow-hidden lg:max-w-none lg:flex">
        <div className="flex-1 bg-white px-6 py-8 lg:p-12">
          <h3 className="text-2xl font-extrabold text-black sm:text-3xl">Lifetime Membership</h3>
          <p className="mt-6 text-base text-gray-500">
            Pay once and get access to Save My Wordle for the rest of time, as well as automatic access to any future upgrades.
          </p>
          <div className="mt-8">
            <div className="flex items-center">
              <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-primary-reg">
                What's included
              </h4>
              <div className="flex-1 border-t-2 border-gray-200" />
            </div>
            <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
              {includedFeatures.map((feature) => (
                <li key={feature} className="flex items-start lg:col-span-1">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-5 w-5 text-primary-reg" aria-hidden="true" />
                  </div>
                  <p className="ml-3 text-sm text-gray-700">{feature}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
          <p className="text-lg leading-6 font-medium text-black">Pay once, own it forever</p>
          <div className="mt-4 flex items-center justify-center text-5xl font-extrabold text-black">
            <span>$19.99</span>
            <span className="ml-3 text-xl font-medium text-gray-500">USD</span>
          </div>
          <div className="mt-6">
            <div className="rounded-md">
              <button
                onClick={() => signUp({ plan: process.env.REACT_APP_STRIPE_LIFETIME, type: 'payment' })}
                className="bg-black border border-transparent rounded-md shadow px-3 py-2 inline-flex items-center text-sm font-medium text-white hover:bg-primary-900"
              >
                {
                  submitting ?
                    <Spinner color="#ffffff" />
                    : 'Get Access'
                }
              </button>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Paywall;
