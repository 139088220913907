import React, { useContext, useEffect, useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { AppContext } from '../../../contexts';


const Bar = ({ label, average, maxAverage }) => {
  const maxHeight = 56;
  const n = (average / maxAverage) * maxHeight;
  const calcHeight = (n) => {
    if (n === 0) {
      return .5
    }
    else if (n > 0) {
      return Math.ceil(n / 4.0) * 4;
    }
  }

  const height = 'h-' + calcHeight(n);

  return (
    <div className="relative flex flex-col items-center flex-grow pb-5">
      <span className="absolute top-0 -mt-6 text-xs font-medium text-gray-500">{average.toFixed(2)}</span>
      <div className={`relative flex justify-center w-full bg-primary-reg ${height} rounded-t-lg`}></div>
      <span className="absolute bottom-0 text-xs font-bold text-black">{label}</span>
    </div>
  )
}

const MonthsAverage = () => {
  const { userId } = useContext(AppContext);
  const [averages, setAverages] = useState(null);
  const [maxAvg, setMaxAvg] = useState(0);
  const firestore = useFirestore();

  useEffect(() => {
    const getMonthlyAverages = async () => {
      const averagesSnap = await firestore.collection('users').doc(userId).collection('statistics').doc('monthlyAverages').get();
      const data = averagesSnap.data();
      let averagesArray = [];
      let max = 0;
      if (data) {
        Object.keys(data).map(month => {
          if (data[month].avg > max) {
            max = data[month].avg;
          }
          return averagesArray.push({ avg: data[month].avg, month: month });
        })
        const sortedAveragesArray = averagesArray.sort((a, b) => {
          const aDateArray = a.month.split("-");
          const bDateArray = b.month.split("-");
          const aDate = new Date(aDateArray[0], aDateArray[1] - 1);
          const bDate = new Date(bDateArray[0], bDateArray[1] - 1);

          if (aDate === bDate) {
            return 0
          } else if (aDate > bDate) {
            return 1
          } else return -1
        })
        setAverages(sortedAveragesArray);
        setMaxAvg(max);
      }
    }
    if (!averages) {
      getMonthlyAverages()
    }
  }, [userId, averages, firestore]);

  return (
    <div className="py-5 px-4 sm:py-6 sm:px-6">
      <div
        className="h-60 sm:h-56 pt-4"
      >
        <div className="flex flex-col items-center w-full max-w-screen-md">
          <div className="flex items-end flex-grow w-full mt-2 space-x-2 sm:space-x-3">
            {
              averages && averages.length
                ? averages.map((month, index) => {
                  const options = { year: 'numeric', month: 'short' };
                  const dateArray = month.month.split("-");
                  const monthLabel = new Date(dateArray[0], dateArray[1] - 1).toLocaleDateString("en-US", options);
                  return (
                    <Bar key={index} average={month.avg} label={monthLabel} maxAverage={maxAvg} />
                  )
                }) : null
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default MonthsAverage;