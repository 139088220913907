import React, { useContext, useState } from 'react';
import { Header } from '../../../ui';
import { useHistory } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { AppContext } from '../../../contexts';
import { CheckIcon, ExclamationCircleIcon } from '@heroicons/react/solid';
import * as Yup from 'yup';
import { useFirestore } from 'react-redux-firebase';
import { Input, File } from '../../../components/Forms';
import Loader from 'react-loader-spinner';
import PhoneSettings from './PhoneSettings';



const User = () => {
  const history = useHistory();
  const { userInfo, userId, mixpanel } = useContext(AppContext);
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const firestore = useFirestore();

  const onSubmit = formValues => {
    setSubmitting(true);
    setStatus(null);

    const { username, firstName, lastName, profilePicUrl } = formValues;

    const initials = firstName && lastName
      ? firstName[0].toUpperCase() + lastName[0].toUpperCase()
      : username[0].toUpperCase()

    firestore
      .collection('users')
      .doc(userId)
      .update({
        username,
        firstName: firstName !== '' ? firstName : null,
        lastName: lastName !== '' ? lastName : null,
        initials,
        profilePicUrl: profilePicUrl !== '' ? profilePicUrl : null,
      })
      .then(() => {
        mixpanel.track('Updated user settings', {
          distinct_id: userId
        })
        setSubmitting(false);
        setError(null);
        setStatus('saved');
      })
      .catch(err => {
        setSubmitting(false);
        setStatus('error');
        setError(err.message);
      })
  }

  const initialValues = {
    username: userInfo && userInfo.username ? userInfo.username : '',
    firstName: userInfo && userInfo.firstName ? userInfo.firstName : '',
    lastName: userInfo && userInfo.lastName ? userInfo.lastName : '',
    profilePicUrl: userInfo && userInfo.profilePicUrl ? userInfo.profilePicUrl : '',
  }

  const ProfileSchema = Yup.object().shape({
    username: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
  });

  const renderStatus = () => {
    if (status === 'saved') {
      return (
        <p className="text-primary-900">
          <span className="inline-flex items-center px-2 text-xs text-medium mt-2">
            <CheckIcon className="mr-1.5 h-4 w-4" />
            Saved
          </span>
        </p>
      )
    } else if (status === 'error') {
      return (
        <p className="text-red-500">
          <span className="inline-flex items-center px-2 text-xs text-medium">
            <ExclamationCircleIcon className="mr-1.5 h-4 w-4" />
            {error}
          </span>
        </p>
      )
    } else return null
  }

  return (
    <>
      <Header
        title="User Profile"
        back={true}
        backAction={history.goBack}
      />
      <div className="mt-6">
        <div className="space-y-6">
          <Formik
            initialValues={initialValues}
            validationSchema={ProfileSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            <Form action="#" method="POST">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  <div className="md:grid md:grid-cols-3">
                    <div className="md:col-span-1 md:pr-6">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">User Profile</h3>
                      <p className="mt-1 text-sm text-gray-500">Edit your information for your account.</p>
                    </div>
                    <div className="mt-5 sm:mt-0 md:col-span-2">
                      <div className="md:grid md:grid-cols-4 md:gap-6">
                        <div className='mt-4 md:mt-0 md:col-span-2'>
                          <Field
                            name='username'
                            label='Username'
                            type='text'
                            component={Input}
                          />
                        </div>
                      </div>
                      <div className="md:grid md:grid-cols-4 md:gap-6 mt-4">
                        <div className='mt-4 md:mt-0 md:col-span-2'>
                          <Field
                            name='firstName'
                            label='First Name'
                            type='text'
                            component={Input}
                          />
                        </div>
                        <div className='mt-4 md:mt-0 md:col-span-2'>
                          <Field
                            name='lastName'
                            label='Last Name'
                            type='text'
                            component={Input}
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <div className="mt-4 md:mt-0 md:col-span-3">
                          <Field
                            name="profilePicUrl"
                            label="Profile Picture"
                            component={File}
                            logoRef={`users/${userId}/images`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <div className="md:mr-6">
                    {renderStatus()}
                  </div>
                  <button
                    type="reset"
                    className="btn-secondary mr-2 btn-small"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn-primary btn-small"
                    disabled={submitting}
                  >
                    {
                      submitting
                        ? <Loader type="TailSpin" height="18" width="18" color="#000" />
                        : 'Save'
                    }
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
      <div className="mt-6">
        <PhoneSettings />
      </div>
    </>
  )
}

export default User