const { DateTime } = require("luxon");

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const capitalizeFirst = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const validateEmail = (email) => {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    // eslint-disable-line
    return true;
  } else return false;
};

export const getTodaysWordle = () => {
  const baseDate = new Date("2022-02-23".replace(/-/g, "/"));
  const currentDate = new Date();
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  const utc1 = Date.UTC(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
  const utc2 = Date.UTC(
    baseDate.getFullYear(),
    baseDate.getMonth(),
    baseDate.getDate()
  );

  const daysDiff = Math.floor((utc1 - utc2) / _MS_PER_DAY);

  const baseWordle = 249;

  const currentWordle = baseWordle + daysDiff;

  return currentWordle;
};

export const convertDateToWordle = (date) => {
  const baseDate = new Date("2022-02-23".replace(/-/g, "/"));
  const currentDate = new Date(date);
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  const utc1 = Date.UTC(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
  const utc2 = Date.UTC(
    baseDate.getFullYear(),
    baseDate.getMonth(),
    baseDate.getDate()
  );

  const daysDiff = Math.floor((utc1 - utc2) / _MS_PER_DAY);

  const baseWordle = 249;

  const currentWordle = baseWordle + daysDiff;

  return currentWordle;
};

export const convertWordleToDate = (num, format) => {
  const baseNum = 249;
  const daysDiff = num - baseNum;

  const newDate = DateTime.fromISO("2022-02-23", { zone: "utc" })
    .plus({ days: daysDiff })
    .toFormat(format);

  return newDate;
};

export const convertEmojiArray = (array) => {
  let evaluations = {};
  let row = 0;
  let col = 0;

  array.forEach((item) => {
    const result =
      item === "⬛" || item === "⬜"
        ? "absent"
        : item === "🟨" || item === "🟦"
        ? "present"
        : item === "🟩" || item === "🟧"
        ? "correct"
        : "absent";

    if (evaluations[row]) {
      const currentRow = evaluations[row];
      currentRow.push(result);
    } else {
      evaluations[row] = [result];
    }

    if (col === 4) {
      row++;
      col = 0;
    } else {
      col++;
    }
  });

  return evaluations;
};
