import React from 'react';
import Loader from 'react-loader-spinner';

const Spinner = ({ color, height }) => {
  return (
    <Loader
      type="TailSpin"
      color={color ? color : '#10B981'}
      height={height ? height : '18'}
      width={height ? height : '18'}
    />
  )
}

export default Spinner;