import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { classNames } from '../../utils';

const Sidebar = ({ routes }) => {
  const location = useLocation();

  return (
    <div>
      <nav aria-label="Sidebar" className="sticky top-4 divide-y divide-gray-300">
        <div className="pb-6 space-y-1">
          {routes.filter(route => route.display === 'main').map((item) => {
            const currentRoute = location.pathname === item.path;
            return (
              <Link
                key={item.name}
                to={item.path}
                className={classNames(
                  currentRoute ? 'text-gray-900' : 'text-gray-600 group-hover:text-gray-700',
                  'group flex items-center px-3 py-2 text-sm font-medium rounded-md group-hover:text-gray-900'
                )}
                aria-current={item.current ? 'page' : undefined}
              >
                <item.icon
                  className={classNames(
                    currentRoute ? 'text-gray-900' : 'text-gray-400 group-hover:text-gray-700',
                    'flex-shrink-0 -ml-1 mr-3 h-4 w-4'
                  )}
                  aria-hidden="true"
                />
                <span className="truncate group-hover:text-gray-900">{item.name}</span>
              </Link>
            )
          })}
        </div>
        <div className="pt-4">
          <div className="mt-2 space-y-2" aria-labelledby="communities-headline">
            {routes.filter(route => route.display === 'secondary').map(route => {
              if (route.external) {
                return (
                  <a
                    key={route.name}
                    target="_blank"
                    href={route.path}
                    rel="noopener noreferrer"
                    className="group flex items-center px-3 py-1 text-sm font-medium text-gray-600 rounded-md hover:text-black"
                  >
                    <span className="truncate">{route.name}</span>
                  </a>
                )
              } else {
                return (
                  <Link
                    key={route.name}
                    to={route.path}
                    className="group flex items-center px-3 py-1 text-sm font-medium text-gray-600 rounded-md hover:text-black"
                  >
                    <span className="truncate">{route.name}</span>
                  </Link>
                )
              }
            })}
          </div>
        </div>
      </nav >
    </div>
  )
}

export default Sidebar;