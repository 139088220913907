import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ScoresHome from './ScoresHome';
import ScoreSubmit from './ScoreSubmit';

const MyScores = () => {
  return (
    <Switch>
      <Route exact path="/scores" component={ScoresHome} />
      <Route exact path="/scores/submit" component={ScoreSubmit} />
    </Switch>
  )
}

export default MyScores;