import React, { useContext, useEffect, useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { AppContext } from '../../../contexts';
import { Link } from 'react-router-dom';

const MyLeagues = () => {
  const [leagues, setLeagues] = useState(null);
  const { userId } = useContext(AppContext);
  const firestore = useFirestore();

  useEffect(() => {
    const getManagedLeagues = async () => {
      const leaguesArray = [];
      const leaguesSnap = await firestore.collection('leagues').where('managers', 'array-contains', userId).get();
      leaguesSnap.docs.forEach(doc => {
        const leagueData = doc.data();
        leaguesArray.push({
          id: doc.id,
          membersCount: leagueData.members.length,
          name: leagueData.name
        })
      })
      setLeagues(leaguesArray);
    }
    getManagedLeagues();
  }, [userId, firestore]);

  if (leagues && leagues.length) {
    return (
      <div className="mt-4">
        <h4 className="text-xs leading-6 font-medium text-gray-500 uppercase ml-1">Managed Leagues</h4>
        <div className="overflow-hidden bg-white rounded-lg shadow">
          <ul className="">
            <li className="border-b border-gray-300 px-3">
              <div className="block">
                <div className="py-2">
                  <div className="flex items-center justify-between">
                    <p className="text-xs font-medium text-gray-700 truncate uppercase">League Name</p>
                    <div className="ml-2 flex-shrink-0 flex">
                      <p className="inline-flex text-xs text-gray-700 uppercase">
                        Members
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            {leagues && leagues.map(league => (
              <Link to={`/leagues/detail/${league.id}`} key={league.id}>
                <li className="border-b border-gray-300 px-3 hover:bg-gray-50">
                  <div className="block">
                    <div className="py-3">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <p className="ml-2 flex items-center text-sm text-black font-medium">
                            {league.name}
                          </p>
                        </div>
                        <div className="flex-shrink-0 flex">
                          <p className="inline-flex text-sm text-gray-500">
                            {league.membersCount}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </Link>
            ))}
          </ul>
        </div>
      </div>
    )
  } else return null
}

export default MyLeagues;