import React, { useContext, useState, useEffect } from 'react';
import { HashtagIcon, CheckCircleIcon, PresentationChartLineIcon } from '@heroicons/react/outline';
import Spinner from '../../../components/Spinner';
import { Link } from 'react-router-dom';
import PlayerAverage from './PlayerAverage';
import { AppContext } from '../../../contexts';
import ProFeature from '../../../components/ProFeature';
import MonthsAverage from './MonthsAverage';
import PersonalScoreCounts from './PersonalScoreCounts';

const PersonalStats = ({ totalWordles, successRate, average }) => {
  const { userInfo } = useContext(AppContext);
  const [pro, setPro] = useState(false);

  useEffect(() => {
    setPro(true);
  }, [userInfo])


  const stats = [
    { id: 1, name: 'Total Wordles', stat: totalWordles, icon: HashtagIcon, change: '122', changeType: 'increase' },
    { id: 2, name: 'Win Percentage', stat: successRate, icon: CheckCircleIcon, change: '5.4%', changeType: 'increase' },
    { id: 3, name: 'Average Score', stat: average, icon: PresentationChartLineIcon, change: '3.2%', changeType: 'decrease' },
  ]

  return (
    <div>
      <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
          >
            <dt>
              <div className="absolute bg-primary-900 rounded-md p-3">
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <p className="ml-16 text-sm font-medium text-gray-500 truncate">{item.name}</p>
            </dt>
            <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
              <span className="text-2xl font-semibold text-gray-900">
                {
                  item.stat
                    ? item.stat
                    : <Spinner height="24" color="#538F4F" />
                }</span>
              <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                <div className="text-sm">
                  <Link to="/scores" className="font-medium hover:text-black text-primary-900">
                    View all
                  </Link>
                </div>
              </div>
            </dd>
          </div>
        ))}
      </dl>
      <div className="mt-6 sm:grid sm:grid-cols-2 gap-5 space-y-5 sm:space-y-0">
        <div className="bg-white shadow px-4 py-5 rounded-lg">
          <p className="text-sm font-medium text-gray-500 truncate">Average Over Time</p>
          {
            pro
              ? (
                <div>
                  <PlayerAverage wordleNumber />
                </div>
              ) : (
                <div className="flex justify-center items-center h-56 sm:h-72">
                  <ProFeature cta />
                </div>
              )
          }

        </div>
        <div className="bg-white shadow rounded-lg px-4 py-5">
          <p className="text-sm font-medium text-gray-500 truncate">Monthly Averages</p>
          {
            pro
              ? (
                <div>
                  <MonthsAverage />
                </div>
              ) : (
                <div className="flex justify-center items-center h-56 sm:h-72">
                  <ProFeature cta />
                </div>
              )
          }

        </div>
      </div>
      <div className="mt-6">
        <div className="bg-white shadow px-4 py-5 rounded-lg">
          <PersonalScoreCounts />
        </div>
      </div>
    </div>
  )
}

export default PersonalStats;