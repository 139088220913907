import { CheckIcon, ExclamationCircleIcon } from "@heroicons/react/solid";
import React, { useContext, useState } from "react";
import { TextArea } from "../../components/Forms";
import { Header } from "../../ui";
import { useFirebase } from "react-redux-firebase";
import { Formik, Form, Field } from "formik";
import Spinner from "../../components/Spinner";
import { AppContext } from "../../contexts";

const ScoreSubmit = ({ history }) => {
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const firebase = useFirebase();
  const { userId } = useContext(AppContext);

  const onSubmit = async ({ msg }) => {
    setSubmitting(true);
    setStatus(null);

    const recordScore = firebase
      .functions()
      .httpsCallable("scores-recordScore");
    const { data } = await recordScore({
      msg,
      userId,
    });

    if (data.success) {
      setStatus("saved");
      setSubmitting(false);
    } else {
      setStatus("error");
      setError(
        data.message
          ? data.message
          : "There was a problem saving your score. Please check to make sure it is formatted correctly."
      );
      setSubmitting(false);
    }
  };

  const renderStatus = () => {
    if (status === "saved") {
      return (
        <span className="text-primary-900">
          <span className="inline-flex items-center px-2 text-xs text-medium">
            <CheckIcon className="mr-1.5 h-4 w-4" />
            Saved
          </span>
        </span>
      );
    } else if (status === "error") {
      return (
        <span className="text-red-500">
          <span className="inline-flex items-center px-2 text-xs text-medium">
            <ExclamationCircleIcon className="mr-1.5 h-4 w-4" />
            {error}
          </span>
        </span>
      );
    } else return null;
  };

  const validate = (values) => {
    const errors = {};

    if (!values.msg) {
      errors.msg = "Please paste your score.";
    }

    return errors;
  };

  return (
    <div>
      <Header title="Submit Score" back backAction={history.goBack} />
      <div className="space-y-6 mt-6">
        <Formik
          initialValues={{ msg: "" }}
          onSubmit={onSubmit}
          validate={validate}
        >
          <Form action="#" method="POST">
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                <div className="md:grid md:grid-cols-3">
                  <div className="md:col-span-1 md:pr-6">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Submit A Score
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Copy and paste your score exactly as it appears from
                      Wordle, including emojis.
                    </p>
                  </div>
                  <div className="mt-5 sm:mt-0 md:col-span-2">
                    <div className="md:grid md:grid-cols-4 md:gap-6">
                      <div className="mt-4 md:mt-0 md:col-span-2">
                        <Field
                          name="msg"
                          label="Paste your score from Wordle here"
                          type="text"
                          component={TextArea}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end px-4 py-3 bg-gray-50 text-right sm:px-6">
                <div className="md:mr-6">{renderStatus()}</div>
                <button
                  type="submit"
                  className="btn-primary btn-small"
                  disabled={submitting}
                >
                  {submitting ? <Spinner color="#FFFFFF" /> : "Save Score"}
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ScoreSubmit;
