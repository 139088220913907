import { CogIcon } from '@heroicons/react/outline';
import React, { useContext, useState } from 'react';
import { useFirebase } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import { AppContext } from '../../contexts';

const SettingsMenu = () => {
  const firebase = useFirebase();
  const { userId, mixpanel, userInfo } = useContext(AppContext);
  const [submitting, setSubmitting] = useState(false);

  const viewPortal = async ({ discount }) => {
    setSubmitting(true);
    mixpanel.track('View billing portal', {
      distinct_id: userId
    });
    const createPortalSession = firebase.functions().httpsCallable('stripe-createPortalSession');
    const { data } = await createPortalSession({
      userId,
      email: userInfo.email,
      discount: discount ? process.env.REACT_APP_STRIPE_DISCOUNT : null,
      customerId: userInfo && userInfo.stripeCustomer ? userInfo.stripeCustomer : null
    });
    return window.location.href = data
  }

  const settingsMap = [
    {
      name: 'Account Settings',
      icon: CogIcon,
      description: 'General information and settings regarding your account.',
      subSettings: [
        {
          name: 'My User Profile',
          route: '/settings/user'
        },
        {
          name: 'Account & Billing',
          onClick: () => {
            viewPortal({ discount: true })
          }
        }
      ]
    },
  ]

  return (
    <div>
      <div className="pb-5 border-b border-gray-200">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Settings</h3>
      </div>
      <div className="grid grid-cols-3 sm:divide-x divide-gray-200">
        {settingsMap.map((setting, index) => {
          return (
            <div className="col-span-3 sm:col-span-1 py-5 px-2" key={index}>
              <div className="">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <setting.icon className="h-5 w-5 text-primary-500" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <p className="text-md font-medium text-gray-900">{setting.name}</p>
                  </div>
                </div>
              </div>
              <div>
                <p className="text-sm mt-2 text-gray-500">{setting.description}</p>
              </div>
              <div className="mt-2">
                {setting.subSettings.map((subSetting, index) => {
                  if (subSetting.route) {
                    return (
                      <div key={index}>
                        <Link to={subSetting.route} className="text-link-primary text-sm">
                          {subSetting.name}
                        </Link>
                      </div>
                    )
                  } else if (subSetting.onClick) {
                    return (
                      <div key={index}>
                        {
                          !submitting
                            ? (
                              <button onClick={subSetting.onClick} className="text-link-primary text-sm">
                                {subSetting.name}
                              </button>
                            ) : (
                              <Spinner color="#538f4f" />
                            )
                        }
                      </div>
                    )
                  } else return null
                })}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SettingsMenu;