import { ArrowSmRightIcon } from '@heroicons/react/outline';
import React from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../../components/Spinner';

const HeaderCharts = ({ todaysWordle, worldAverage }) => {

  return (
    <div>
      <div className="grid sm:grid-cols-2 gap-3 sm:gap-5">
        <div className="bg-white py-5 px-4 sm:py-6 sm:px-6 shadow rounded-lg overflow-hidden">
          <p className="text-sm font-medium text-gray-500 truncate">Your Score Today</p>
          {
            todaysWordle
              ? (
                <span className="text-2xl font-semibold text-gray-900">{todaysWordle}<span className="text-gray-500 font-normal text-base">/6</span></span>
              ) : (
                <Link
                  to="/instructions"
                  className="text-sm font-bold text-primary-900 hover:text-primary-600 flex items-center mt-1">
                  Submit Score <ArrowSmRightIcon className="h-5" />
                </Link>
              )
          }
        </div>
        <div className="bg-white py-5 px-4 sm:py-6 sm:px-6 shadow rounded-lg">
          <p className="text-sm font-medium text-gray-500 truncate">Today's Average Score</p>
          <span className="text-2xl font-semibold text-gray-900">
            {
              worldAverage && worldAverage > 0
                ? worldAverage
                : worldAverage === 'no data'
                  ? <span className="text-sm text-gray-400 font-normal italic">Waiting on first score</span>
                  : <Spinner height="24" color="#538F4F" />
            }
          </span>
        </div>
      </div>
    </div>
  )
}

export default HeaderCharts;