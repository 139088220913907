import React, { useEffect, useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
const maxHeight = 54;

const Bar = ({ label, percent, maxPercent }) => {
  const n = (percent / maxPercent) * maxHeight;
  const calcHeight = (n) => {
    if (n === 0) {
      return .5
    }
    else if (n > 0) {
      return Math.ceil(n / 4.0) * 4;
    }
  }

  const height = 'h-' + calcHeight(n);

  return (
    <div className="relative flex flex-col items-center flex-grow pb-5">
      <span className="absolute top-0 -mt-6 text-xs font-medium text-gray-500">{(percent * 100).toFixed(2)}%</span>
      <div className={`relative flex justify-center w-full bg-primary-reg ${height} rounded-t-lg`}></div>
      <span className="absolute bottom-0 text-xs font-bold text-black">{label}</span>
    </div>
  )
}

const ScoreCountChart = ({ wordleNumber }) => {
  // const [average, setAverage] = useState(null);
  const [scoreCounts, setScoreCounts] = useState(null);
  const [gamesCount, setGamesCount] = useState(0);
  const [barConfig, setBarConfig] = useState(null);
  const firestore = useFirestore();

  useEffect(() => {
    const getScores = async () => {
      const scoresSnap = await firestore.collection('worldStats').doc('scoreCounts').collection('daily').doc(`${wordleNumber}`).get();
      const exists = scoresSnap.exists;
      if (exists) {
        const { gamesPlayed, scores } = await scoresSnap.data();

        let totalScoresCalc = 0;

        Object.keys(scores).map(score => {
          if (score !== 'X') {
            return totalScoresCalc = totalScoresCalc + (score * scores[score])
          } else return null
        })

        // const avgScore = totalScoresCalc / gamesPlayed;

        setGamesCount(gamesPlayed);
        // setAverage(avgScore.toFixed(2));
        setScoreCounts(scores);
      }
    }
    getScores();
  }, [firestore, wordleNumber]);

  useEffect(() => {
    let config = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      X: 0
    }

    const newConfig = { maxPercent: 0, scores: {} }

    if (scoreCounts) {
      Object.keys(config).map(item => {
        if (scoreCounts[item]) {
          const percCalc = (scoreCounts[item] / gamesCount)
          if (percCalc > newConfig.maxPercent) {
            newConfig.maxPercent = percCalc;
          }
          return newConfig.scores[item] = percCalc;
        } else {
          return newConfig.scores[item] = 0
        }
      })
      setBarConfig(newConfig);
    }

  }, [scoreCounts, gamesCount]);

  return (
    <div className="py-5 px-4 sm:py-6 sm:px-6">
      <div>
        <p className="text-sm font-medium text-gray-500 truncate">Today's Scores Breakdown</p>
      </div>
      <div
        className="h-64 pt-4"
      >
        <div className="flex flex-col items-center w-full max-w-screen-md">
          <div className="flex items-end flex-grow w-full mt-2 space-x-2 sm:space-x-3">
            {barConfig && Object.keys(barConfig.scores).map(item => (
              <Bar key={item} percent={barConfig.scores[item]} label={item} maxPercent={barConfig.maxPercent} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScoreCountChart;