import { EyeOffIcon } from '@heroicons/react/outline';
import React from 'react';
import { Link } from 'react-router-dom';

const ProFeature = ({ cta }) => {
  if (cta) {
    return (
      <div className="">
        <div className="mt-0.5 text-gray-300 font-medium text-base flex items-center justify-center">
          <EyeOffIcon className="h-6 text-gray-300 mr-1" />
          Pro Feature
        </div>
        <div className="flex justify-center mt-2">
          <Link
            to="/pro"
            className="text-sm font-medium text-gray-500 hover:text-primary-800"
          >
            Preview with free trial <span aria-hidden="true">&rarr;</span>
          </Link>
        </div>
      </div>
    )
  }
  else return (
    <div className="group">
      <Link to="/pro" className="mt-0.5 text-gray-300 font-medium text-sm group-hover:text-primary-900 flex items-center">
        <EyeOffIcon className="h-6 text-gray-300 group-hover:text-primary-900 mr-1" />
        Pro Feature
      </Link>
    </div>
  )
}

export default ProFeature;