import React from 'react';
import Spinner from '../../../../components/Spinner';
import { classNames } from '../../../../utils';
import { Link } from 'react-router-dom';

const medals = {
  1: '🥇',
  2: '🥈',
  3: '🥉'
}

const MembersTable = ({ leaders, hasLoaded }) => {

  if (leaders && hasLoaded) {
    return (
      <div className="mt-4">
        <h3 className="text-sm leading-6 font-medium text-gray-500">Current Month Standings</h3>
        <div className="mt-1 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:mx-0 rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="py-2.5 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6">
                  Name
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-2.5 text-left text-xs font-medium uppercase tracking-wide text-gray-500 lg:table-cell text-center"
                >
                  Wordles Completed
                </th>
                <th scope="col" className="px-3 py-2.5 text-center text-xs font-medium uppercase tracking-wide text-gray-500">
                  Points
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {leaders.map((person, index) => (
                <tr key={person.id} className="">
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                    <span className="flex items-center">
                      {medals[index + 1] && person.points
                        ? (
                          <span className="text-xl mr-1">{medals[index + 1]}</span>
                        ) : null}
                      <Link to={`/player/${person.id}`} className="hover:text-primary-dark">{person.name}</Link>
                    </span>
                    <dl className="font-normal lg:hidden flex">
                      <dt className="truncate text-gray-500 sm:hidden">Games Played: </dt>
                      <dd className="truncate text-gray-500 sm:hidden ml-1">{person.gamesPlayed}</dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell text-center">{person.gamesPlayed}</td>
                  <td className="px-3 text-center">
                    <p className={classNames(
                      (index + 1) === 1 ? 'bg-yellow-400 text-white'
                        : (index + 1) === 2 ? 'bg-gray-400 text-white'
                          : (index + 1) === 3 ? 'bg-amber-600 text-white' : 'bg-gray-200 text-black',
                      "inline-flex text-sm text-black font-medium px-3.5 py-2 rounded-lg"
                    )}>
                      {person.points}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div >
    )
  } else return (
    <div className="py-3 text-center">
      <div className="flex justify-center">
        <Spinner height="30" />
      </div>
      <p className="mt-2 italics text-xs font-semibold">Loading League...</p>
    </div>
  );
}

export default MembersTable;