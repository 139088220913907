import React, { Fragment, useContext, useEffect, useState } from "react";
import { CheckIcon, MinusIcon } from "@heroicons/react/solid";
import { classNames } from "../../utils";
import { Link } from "react-router-dom";
import { useFirebase } from "react-redux-firebase";
import { AppContext } from "../../contexts";
import Spinner from "../../components/Spinner";

const tiers = [
  {
    name: "Free",
    key: "Basic",
    href: "#",
    priceMonthly: 0,
    description: "Default access to Save My Wordle.",
    hideButton: true,
  },
  {
    name: "Pro - Monthly",
    key: "Essential",
    href: "#",
    priceMonthly: 1.99,
    description:
      "Get the ability to view your stats, breakdowns on each daily wordle, and competitors stats in leagues.",
    pricingPlan: process.env.REACT_APP_STRIPE_PLAN,
    pricingType: "subscription",
  },
  {
    name: "Pro - Lifetime Membership",
    key: "Premium",
    href: "#",
    priceMonthly: 19.99,
    description:
      "Same pro features -- but lifetime access instead of a monthly fee. Includes access to all future updates.",
    pricingPlan: process.env.REACT_APP_STRIPE_LIFETIME,
    pricingType: "payment",
  },
];
const sections = [
  {
    name: "Personal Scores",
    features: [
      {
        name: "Save historical scores",
        tiers: { Basic: true, Essential: true, Premium: true },
      },
      {
        name: "View score details",
        tiers: { Basic: false, Essential: true, Premium: true },
      },
    ],
  },
  {
    name: "Personal Dashboard",
    features: [
      {
        name: "Average over time",
        tiers: { Essential: true, Premium: true },
      },
      {
        name: "Monthly averages",
        tiers: { Essential: true, Premium: true },
      },
      {
        name: "Guess distribution",
        tiers: { Essential: true, Premium: true },
      },
      {
        name: "Vs. world statistics",
        tiers: { Essential: true, Premium: true },
      },
    ],
  },
  {
    name: "Leagues",
    features: [
      {
        name: "Create & Join Leagues",
        tiers: { Basic: true, Essential: true, Premium: true },
      },
      {
        name: "Get points & compete in leagues",
        tiers: { Basic: true, Essential: true, Premium: true },
      },
      {
        name: "See breakdowns of other league players' stats",
        tiers: { Essential: true, Premium: true },
      },
    ],
  },
];

const ProPage = () => {
  const { userInfo, userId, mixpanel } = useContext(AppContext);
  const [loggedMixpanel, setLoggedMixpanel] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const firebase = useFirebase();

  useEffect(() => {
    if (!loggedMixpanel && userId) {
      mixpanel.track("View Pro", {
        distinct_id: userId,
      });
      setLoggedMixpanel(true);
    }
  }, [loggedMixpanel, mixpanel, userId]);

  const signUp = async ({ plan, type }) => {
    setSubmitting(true);
    mixpanel.track("Checkout", {
      distinct_id: userId,
    });
    const createCheckoutSession = firebase
      .functions()
      .httpsCallable("stripe-createCheckoutSession");
    const { data } = await createCheckoutSession({
      userId,
      email: userInfo.email,
      plan,
      type,
    });
    return (window.location.href = data);
  };

  return (
    <div>
      <div className="flex">
        <div className="flex-1 md:flex md:justify-between">
          <p className="text-sm text-black">Why a paid version?</p>
          <p className="mt-3 text-sm md:mt-0 md:ml-6">
            <Link
              to="/letter"
              className="whitespace-nowrap font-medium text-black hover:text-primary-reg"
            >
              A letter from our creator <span aria-hidden="true">&rarr;</span>
            </Link>
          </p>
        </div>
      </div>
      <div className="mt-2">
        <div className="max-w-7xl mx-auto">
          {/* xs to lg */}
          <div className="max-w-2xl mx-auto space-y-16 lg:hidden">
            {tiers.map((tier, tierIdx) => (
              <section
                key={tier.name}
                className="mt-1 bg-white py-16 rounded-lg overflow-hidden shadow"
              >
                <div className="px-4 mb-8">
                  <h2 className="text-lg leading-6 font-medium text-gray-900">
                    {tier.name}
                  </h2>
                  <p className="mt-4">
                    <span className="text-4xl font-extrabold text-gray-900">
                      ${tier.priceMonthly}
                    </span>{" "}
                    <span className="text-base font-medium text-gray-500">
                      /mo
                    </span>
                  </p>
                  <p className="mt-4 text-sm text-gray-500">
                    {tier.description}
                  </p>
                  {tier.hideButton ? null : (
                    <button
                      onClick={() =>
                        signUp({
                          plan: tier.pricingPlan,
                          type: tier.pricingType,
                        })
                      }
                      className="mt-6 block border border-gray-800 rounded-md bg-gray-800 w-full py-2 text-sm font-semibold text-white flex justify-center hover:bg-gray-900"
                    >
                      {submitting ? (
                        <Spinner color="#FFFFFF" className="mx-auto" />
                      ) : (
                        `Buy ${tier.name}`
                      )}
                    </button>
                  )}
                </div>

                {sections.map((section) => (
                  <table key={section.name} className="w-full">
                    <caption className="bg-gray-50 border-t border-gray-200 py-3 px-4 text-sm font-medium text-gray-900 text-left">
                      {section.name}
                    </caption>
                    <thead>
                      <tr>
                        <th className="sr-only" scope="col">
                          Feature
                        </th>
                        <th className="sr-only" scope="col">
                          Included
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {section.features.map((feature) => (
                        <tr
                          key={feature.name}
                          className="border-t border-gray-200"
                        >
                          <th
                            className="py-5 px-4 text-sm font-normal text-gray-500 text-left"
                            scope="row"
                          >
                            {feature.name}
                          </th>
                          <td className="py-5 pr-4">
                            {typeof feature.tiers[tier.key] === "string" ? (
                              <span className="block text-sm text-gray-700 text-right">
                                {feature.tiers[tier.key]}
                              </span>
                            ) : (
                              <>
                                {feature.tiers[tier.key] === true ? (
                                  <CheckIcon
                                    className="ml-auto h-5 w-5 text-green-500"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <MinusIcon
                                    className="ml-auto h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                )}

                                <span className="sr-only">
                                  {feature.tiers[tier.key] === true
                                    ? "Yes"
                                    : "No"}
                                </span>
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ))}

                <div
                  className={classNames(
                    tierIdx < tiers.length - 1 ? "py-5 border-b" : "pt-5",
                    "border-t border-gray-200 px-4"
                  )}
                >
                  {tier.hideButton ? null : (
                    <button
                      onClick={() =>
                        signUp({
                          plan: tier.pricingPlan,
                          type: tier.pricingType,
                        })
                      }
                      className="block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white hover:bg-gray-900 flex justify-center"
                    >
                      {submitting ? (
                        <Spinner color="#FFFFFF" className="mx-auto" />
                      ) : (
                        `Buy ${tier.name}`
                      )}
                    </button>
                  )}
                </div>
              </section>
            ))}
          </div>

          {/* lg+ */}
          <div className="hidden lg:block bg-white py-16 rounded-lg shadow overflow-hidden">
            <table className="w-full h-px table-fixed">
              <caption className="sr-only">Pricing plan comparison</caption>
              <thead className="">
                <tr>
                  <th
                    className="pb-4 px-6 text-sm font-medium text-gray-900 text-left"
                    scope="col"
                  >
                    <span className="sr-only">Feature by</span>
                    <span>Plans</span>
                  </th>
                  {tiers.map((tier) => (
                    <th
                      key={tier.name}
                      className="w-1/4 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left"
                      scope="col"
                    >
                      {tier.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="border-t border-gray-200 divide-y divide-gray-200">
                <tr>
                  <th
                    className="py-8 px-6 text-sm font-medium text-gray-900 text-left align-top"
                    scope="row"
                  >
                    Pricing
                  </th>
                  {tiers.map((tier) => (
                    <td key={tier.name} className="h-full py-8 px-6 align-top">
                      <div className="relative h-full table">
                        <p>
                          <span className="text-4xl font-extrabold text-gray-900">
                            ${tier.priceMonthly}
                          </span>{" "}
                          <span className="text-base font-medium text-gray-500">
                            /mo
                          </span>
                        </p>
                        <p className="mt-4 mb-16 text-sm text-gray-500">
                          {tier.description}
                        </p>
                        {tier.hideButton ? null : (
                          <button
                            onClick={() =>
                              signUp({
                                plan: tier.pricingPlan,
                                type: tier.pricingType,
                              })
                            }
                            className="absolute bottom-0 flex-grow block w-full bg-gray-800 border border-gray-800 rounded-md 5 py-2 text-sm font-semibold text-white hover:bg-gray-900 flex justify-center"
                          >
                            {submitting ? (
                              <Spinner color="#FFFFFF" className="mx-auto" />
                            ) : (
                              `Buy ${tier.name}`
                            )}
                          </button>
                        )}
                      </div>
                    </td>
                  ))}
                </tr>
                {sections.map((section) => (
                  <Fragment key={section.name}>
                    <tr>
                      <th
                        className="bg-gray-50 py-3 pl-6 text-sm font-medium text-gray-900 text-left"
                        colSpan={4}
                        scope="colgroup"
                      >
                        {section.name}
                      </th>
                    </tr>
                    {section.features.map((feature) => (
                      <tr key={feature.name}>
                        <th
                          className="py-5 px-6 text-sm font-normal text-gray-500 text-left"
                          scope="row"
                        >
                          {feature.name}
                        </th>
                        {tiers.map((tier) => (
                          <td key={tier.name} className="py-5 px-6">
                            {typeof feature.tiers[tier.key] === "string" ? (
                              <span className="block text-sm text-gray-700">
                                {feature.tiers[tier.key]}
                              </span>
                            ) : (
                              <>
                                {feature.tiers[tier.key] === true ? (
                                  <CheckIcon
                                    className="h-5 w-5 text-green-500"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <MinusIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                )}

                                <span className="sr-only">
                                  {feature.tiers[tier.key] === true
                                    ? "Included"
                                    : "Not included"}{" "}
                                  in {tier.name}
                                </span>
                              </>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
              <tfoot>
                <tr className="border-t border-gray-200">
                  <th className="sr-only" scope="row">
                    Choose your plan
                  </th>
                  {tiers.map((tier) => (
                    <td key={tier.name} className="pt-5 px-6">
                      {tier.hideButton ? null : (
                        <button
                          onClick={() =>
                            signUp({
                              plan: tier.pricingPlan,
                              type: tier.pricingType,
                            })
                          }
                          className="block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white hover:bg-gray-900 flex justify-center"
                        >
                          {submitting ? (
                            <Spinner color="#FFFFFF" className="mx-auto" />
                          ) : (
                            `Buy ${tier.name}`
                          )}
                        </button>
                      )}
                    </td>
                  ))}
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProPage;
