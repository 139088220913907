import React from 'react';
import Logo from '../../assets/Logo.png';

const Support = () => {
  return (
    <section aria-labelledby="faq-heading" className="">
      <div className="">
        <div className="max-w-xl">
          <img src={Logo} className="h-20" alt="Save My Wordle Logo" />
          <h2 id="faq-heading" className="mt-4 text-2xl font-extrabold tracking-tight text-gray-900">
            Get In Touch
          </h2>
          <p className="mt-4 text-base text-gray-500">
            Having issues or need to ask a question? We can help. Don't hesitate to reach out to us at <strong>support@savemywordle.com</strong>
          </p>
        </div>
      </div>
    </section>
  )
}

export default Support;