import { CalendarIcon, EyeIcon, UserGroupIcon, PresentationChartBarIcon, PaperAirplaneIcon } from '@heroicons/react/outline';
import React, { useContext, useEffect, useState } from 'react';
import { useFirestore, useFirebase } from 'react-redux-firebase';
import { AppContext } from '../../../contexts';
import MembersTable from './MembersTable.js';
import Spinner from '../../../components/Spinner';
//import Leaderboard from './Leaderboard';
import { Link } from 'react-router-dom';
import { InformationCircleIcon } from '@heroicons/react/solid';
import LastMonthLeaders from './LastMonthLeaders';

const LeagueDetail = ({ match }) => {
  const [leagueInfo, setLeagueInfo] = useState(null);
  const [loggedMixpanel, setLoggedMixpanel] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [reload, setReload] = useState(false);
  const [leaders, setLeaders] = useState([]);
  const [lastMonthLeaders, setLastMonthLeaders] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const firebase = useFirebase();
  const firestore = useFirestore();
  const leagueId = match.params.leagueId;
  const { userId, mixpanel } = useContext(AppContext);
  let isMember = leagueInfo && leagueInfo.members.includes(userId);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const d = new Date();
  const month = (d.getMonth() + 1).toString().padStart(2, "0")
  const year = d.getFullYear();

  useEffect(() => {
    if (!loggedMixpanel && userId && leagueInfo) {
      mixpanel.track('View league', {
        distinct_id: userId,
        league: leagueInfo.name
      });
      setLoggedMixpanel(true);
    }
  }, [loggedMixpanel, mixpanel, userId, leagueInfo]);

  useEffect(() => {
    const getLeaders = async () => {
      const getLeaderboard = firebase.functions().httpsCallable('leagues-getPointsLeaders');
      const { data } = await getLeaderboard({ leagueId, date: `${year}-${month}` });

      setLeaders(data);
      setHasLoaded(true);
    }

    const getLastMonthLeaders = async () => {
      const lastMonth = month === '1'
        ? Number('12')
        : (Number(month) - 1);
      const lastMonthConv = lastMonth.toString().padStart(2, "0")
      const lastMonthYear = month === '1' ? year - 1 : year;

      const getLeaderboard = firebase.functions().httpsCallable('leagues-getPointsLeaders');
      const { data } = await getLeaderboard({ leagueId, date: `${lastMonthYear}-${lastMonthConv}` });

      setLastMonthLeaders(data);
    }

    if (leaders.length === 0 || reload) {
      getLeaders();
      setReload(false);
      getLastMonthLeaders();
    }
  }, [firebase, leagueId, leaders, reload, month, year]);


  useEffect(() => {
    const getLeagueInfo = async () => {
      const leagueSnap = await firestore.collection('leagues').doc(leagueId).get();
      const league = leagueSnap.data();
      setLeagueInfo(league);
    }
    if (!leagueInfo || reload) {
      getLeagueInfo()
      setReload(false);
    }
  }, [leagueId, firestore, submitting, leagueInfo, reload]);

  const joinLeague = async () => {
    setSubmitting(true);
    await firestore.collection('leagues').doc(leagueId).update({
      members: firestore.FieldValue.arrayUnion(userId),
      numMembers: firestore.FieldValue.increment(1)
    });
    await firestore.collection('users').doc(userId).update({
      leagues: firestore.FieldValue.arrayUnion(leagueId)
    })
    setReload(true);
    setSubmitting(false);
  }

  if (leagueInfo) {
    const options = { year: 'numeric', month: 'long' };
    const timestamp = new Date(leagueInfo.createdAt.seconds * 1000).toLocaleDateString("en-US", options);

    return (
      <div>
        <header className="">
          <div className="mx-auto xl:flex xl:items-center xl:justify-between">
            <div className="flex-1 min-w-0">
              <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                {leagueInfo.name}
              </h1>
              <p className="text-sm mt-1 text-gray-700">{leagueInfo.description}</p>
              <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <EyeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Public
                </div>
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <UserGroupIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  {leagueInfo.members.length} Members
                </div>
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Created {timestamp}
                </div>
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <PresentationChartBarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Standard Points Scoring
                  <Link
                    className="ml-1 text-sm text-gray-400 hover:text-gray-700 flex items-center"
                    to="/leagues/instructions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InformationCircleIcon className="h-5 w-5" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="mt-5 flex xl:mt-0 xl:ml-4">
              <span className="sm:ml-3 relative z-0">
                {isMember
                  ? (
                    <button
                      onClick={() => { navigator.clipboard.writeText(`Join my ${leagueInfo.name} Wordle league! ${baseUrl}/leagues/detail/${leagueId}`) }}
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                    >
                      <PaperAirplaneIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                      Copy Invite Link
                    </button>
                  ) : (
                    <button
                      onClick={joinLeague}
                      type="button"
                      className="inline-flex items-center px-4 py-2 rounded-md shadow-sm text-sm font-medium text-white bg-primary-reg hover:bg-primary-light"
                    >
                      {
                        submitting
                          ? <Spinner color="#ffffff" />
                          : 'Join League'
                      }
                    </button>
                  )}
              </span>
            </div>
          </div>
        </header>
        <LastMonthLeaders leaders={lastMonthLeaders.slice(0, 3)} hasLoaded={hasLoaded} />
        {/* <Leaderboard leaders={leaders.slice(0, 3)} hasLoaded={hasLoaded} /> */}
        <MembersTable leaders={leaders} hasLoaded={hasLoaded} />
      </div>
    )
  } else {
    return null
  }
}

export default LeagueDetail;