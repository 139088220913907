import { ArrowSmLeftIcon } from '@heroicons/react/outline';
import React from 'react';
import { classNames } from '../../utils';
import { Link } from 'react-router-dom';

export const Header = ({ tabs, back, backAction, actions, title }) => {

  const renderActions = () => {
    if (actions) {
      return (
        <div className="flex md:mt-0 md:absolute md:right-0">
          {actions.map((action, index) => {
            if (action.type === 'link') {
              return (
                <Link
                  key={index}
                  to={action.metadata.linkTo}
                  className="ml-6 inline-flex items-center px-4 py-1.5 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-black hover:bg-white hover:border-black hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 shadow"
                >
                  {action.title}
                </Link>
              )
            } else if (action.type === 'onClick') {
              return (
                <button
                  key={index}
                  onClick={action.metadata.onClick}
                  className="ml-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-black hover:bg-white hover:border-black hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 shadow"
                >
                  {action.title}
                </button>
              )
            } else return null
          })}
        </div>
      )
    }
  }

  const renderTabs = () => {
    if (tabs) {
      return (
        <div className="mt-4">
          <div className="sm:hidden">
            <label htmlFor="current-tab" className="sr-only">
              Select a tab
            </label>
            <select
              id="current-tab"
              name="current-tab"
              className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
              defaultValue={tabs.find((tab) => tab.current).name}
            >
              {tabs.map(tab => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tab.current
                      ? 'border-primary-500 text-primary-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      )
    }
  }

  const renderBack = () => {
    if (back) {
      return (
        <ArrowSmLeftIcon
          onClick={backAction}
          className="h-10 w-10 bg-gray-200 hover:bg-gray-300 mr-2 rounded-full p-2"
        />
      )
    }
  }

  return (
    <div
      className={classNames(
        tabs
          ? "border-b border-gray-200"
          : "",
        "relative pb-5 sm:pb-0"
      )}
    >
      <div className="flex items-left justify-left items-center">
        {renderBack()}
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
        {renderActions()}
      </div>
      {renderTabs()}
    </div>
  )
}


