import React, { useContext, useState } from 'react';
import { AppContext } from '../../contexts';
import { useFirebase } from 'react-redux-firebase';
import Spinner from '../../components/Spinner';

const Friends = () => {
  const [submitting, setSubmitting] = useState(false);
  const { userInfo, userId, mixpanel } = useContext(AppContext);
  const firebase = useFirebase();

  const signUp = async () => {
    setSubmitting(true);
    mixpanel.track('Checkout', {
      distinct_id: userId
    });
    const createCheckoutSession = firebase.functions().httpsCallable('stripe-createPaymentLink');
    const { data } = await createCheckoutSession({ userId, email: userInfo.email, discount: process.env.REACT_APP_STRIPE_FREE });
    return window.location.href = data
  }

  return (
    <div>
      <button
        onClick={signUp}
        className="mt-8 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-primary-reg hover:bg-primary-700 sm:w-auto"
      >
        {
          submitting
            ? <Spinner height={20} color="#FFFFFF" />
            : 'Go Pro'
        }
      </button>
    </div>
  )
}

export default Friends;