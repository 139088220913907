import {
  ArrowSmLeftIcon,
  CalendarIcon,
  UserIcon,
} from "@heroicons/react/solid";
import React, { useContext, useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import Spinner from "../../components/Spinner";
import { AppContext } from "../../contexts";
import ScoreChart from "./ScoreChart";
import AveragesChart from "./AveragesChart";
import PlayerAverage from "../Dashboard/PersonalStats.js/PlayerAverage";
import MonthsAverage from "../Dashboard/PersonalStats.js/MonthsAverage";
import TrialEnding from "../../components/TrialEnding";

const ScoreDetail = ({ match, history, location }) => {
  const scoreId = match.params.scoreId;
  const locationState = location && location.state ? location.state : null;
  const prevPath = locationState ? locationState.prevPath : null;
  const { userInfo, userId, mixpanel } = useContext(AppContext);
  const playerId = match.params.playerId;
  const [scoreInfo, setScoreInfo] = useState(null);
  const [playerInfo, setPlayerInfo] = useState(null);
  const [loggedMixpanel, setLoggedMixpanel] = useState(false);
  const firestore = useFirestore();

  useEffect(() => {
    if (!loggedMixpanel && userId) {
      mixpanel.track("View score detail", {
        distinct_id: userId,
      });
      setLoggedMixpanel(true);
    }
  }, [loggedMixpanel, mixpanel, userId]);

  useEffect(() => {
    const getScoreInfo = async () => {
      const scoreSnap = await firestore
        .collection("users")
        .doc(playerId)
        .collection("scores")
        .doc(scoreId)
        .get();
      const scoreData = scoreSnap.data();
      setScoreInfo(scoreData);
    };
    const getPlayerInfo = async () => {
      if (playerId === userId) {
        setPlayerInfo(userInfo);
      } else {
        const playerSnap = await firestore
          .collection("users")
          .doc(playerId)
          .get();
        const playerData = playerSnap.data();
        setPlayerInfo(playerData);
      }
    };
    if (!scoreInfo) {
      getScoreInfo();
    }
    if (!playerInfo && userInfo) {
      getPlayerInfo();
    }
  }, [scoreId, firestore, playerInfo, scoreInfo, playerId, userInfo, userId]);

  if (scoreInfo && playerInfo) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const from = scoreInfo.date.split("-");
    const timestamp = new Date(
      from[2],
      from[0] - 1,
      from[1]
    ).toLocaleDateString("en-US", options);

    return (
      <div>
        <div>
          <TrialEnding />
        </div>
        <header className="">
          <div className="mx-auto xl:flex xl:items-center xl:justify-between">
            <div className="flex">
              {prevPath && (
                <ArrowSmLeftIcon
                  onClick={history.goBack}
                  className="h-9 w-9 bg-gray-200 hover:bg-gray-300 mr-2 rounded-full p-2"
                />
              )}
              <div className="flex-1 min-w-0">
                <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                  Wordle #{scoreInfo.wordleNumber}
                </h1>
                <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <UserIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {playerInfo.username
                      ? playerInfo.username
                      : `${playerInfo.firstName} ${playerInfo.lastName}`}
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <CalendarIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {timestamp}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 flex xl:mt-0 xl:ml-4"></div>
          </div>
        </header>
        <div className="mt-6 sm:grid sm:grid-cols-2 sm:space-x-4 space-y-4 sm:space-y-0">
          <div className="md:col-span-1">
            <ScoreChart
              score={scoreInfo.score}
              evaluations={scoreInfo.evaluations}
              username={
                playerId === userId
                  ? "Your"
                  : playerInfo.username
                  ? playerInfo.username + "'s"
                  : `${playerInfo.firstName} ${playerInfo.lastName}'s`
              }
            />
          </div>
          <div className="md:col-span-1">
            <AveragesChart wordleNumber={scoreInfo.wordleNumber} />
          </div>
        </div>
        {playerId === userId ? (
          <div className="mt-6 sm:grid sm:grid-cols-2 sm:space-x-4 space-y-4 sm:space-y-0">
            <div className="bg-white shadow px-4 py-5 rounded-lg">
              <p className="text-sm font-medium text-gray-500 truncate">
                Average Over Time
              </p>
              <div>
                <PlayerAverage wordleNumber />
              </div>
            </div>
            <div className="bg-white shadow rounded-lg px-4 py-5">
              <p className="text-sm font-medium text-gray-500 truncate">
                Monthly Averages
              </p>

              <div>
                <MonthsAverage />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  } else {
    return (
      <div className="py-3 text-center">
        <div className="flex justify-center">
          <Spinner height="30" />
        </div>
        <p className="mt-2 italics text-xs font-semibold">
          Loading Score Details...
        </p>
      </div>
    );
  }
};

export default ScoreDetail;
