import React from "react";
import { Switch, Route } from "react-router-dom";
import LeagueCreate from "./LeagueCreate";
import LeaguesHome from "./LeaguesHome";
import LeagueDetail from "./LeagueDetail";
import LeagueInstructions from "./LeagueInstructions";

const Leagues = () => {
  return (
    <div>
      <Switch>
        <Route exact path="/leagues" component={LeaguesHome} />
        <Route exact path="/leagues/create" component={LeagueCreate} />
        <Route
          exact
          path="/leagues/detail/:leagueId"
          component={LeagueDetail}
        />
        <Route
          exact
          path="/leagues/instructions"
          component={LeagueInstructions}
        />
      </Switch>
    </div>
  );
};

export default Leagues;
