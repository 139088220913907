import React, { useState } from 'react';
import { useFirebase } from 'react-redux-firebase';

export const File = ({ field, name, form, label, logoRef, ...props }) => {
  const [error, setError] = useState(null);
  const firebase = useFirebase();
  const { storage } = firebase;

  const renderError = () => {
    if (error) {
      return (
        <p className='mt-2 text-sm text-red-600' id='email-error'>
          {error}
        </p>
      );
    }
  };

  const uploadFile = async logo => {
    if (logo && logoRef) {
      try {
        await storage().ref(`${logoRef}/${logo.name}`).put(logo);
        const url = await storage()
          .ref(logoRef)
          .child(logo.name)
          .getDownloadURL();
        return url
      }
      catch (e) {
        setError(e.message);
      }

    } else return null
  }

  const renderPreview = () => {
    if (field.value) {
      return (
        <span className="inline-block h-20 w-20 rounded-full overflow-hidden bg-white border border-gray-300 flex items-center justify-center">
          <img src={field.value} alt="preview" />
        </span>
      )
    } else {
      return (
        <span className="inline-block h-20 w-20 rounded-full overflow-hidden bg-gray-100">
          <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </span>
      )
    }
  }

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="mt-2 flex items-center">
        <div>
          {renderPreview()}
        </div>
        <label className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <input
            id={label}
            name={field.name}
            type="file"
            style={{ display: 'none' }}
            onChange={(e) => {
              uploadFile(e.target.files[0]).then((url) => {
                if (url) {
                  form.setFieldValue(field.name, url, false)
                }
              })
            }}
          />
          Select
        </label>
      </div>
      {renderError()}
    </div >
  )
}