import React, { useEffect, useState } from "react";
import { Header } from "../../ui";
import { useFirestore } from "react-redux-firebase";

const Bar = ({ label, average, maxAverage }) => {
  const maxHeight = 56;
  const n = (average / maxAverage) * maxHeight;
  const calcHeight = (n) => {
    if (n === 0) {
      return 0.5;
    } else if (n > 0) {
      return Math.ceil(n / 4.0) * 4;
    }
  };

  const height = "h-" + calcHeight(n);

  return (
    <div className="relative flex flex-col items-center flex-grow pb-5">
      <span className="absolute top-0 -mt-8 text-xl font-semibold text-black">
        {average.toFixed(2)}
      </span>
      <div
        className={`relative flex justify-center w-full bg-primary-reg ${height} rounded-t-lg`}
      ></div>
      <span className="absolute bottom-0 text-xs font-bold text-gray-500">
        {label}
      </span>
    </div>
  );
};

const AdminStats = () => {
  const [monthsScores, setMonthsScores] = useState(null);
  const [maxAvg, setMaxAvg] = useState(0);
  const [loading, setLoading] = useState(false);
  const firestore = useFirestore();

  useEffect(() => {
    const getWordles = async () => {
      let wordleMonths = [];
      const wordleSnap = await firestore
        .collection("worldStats")
        .doc("scoreCounts")
        .collection("monthly")
        .get();
      wordleSnap.docs.forEach((doc) => {
        const data = doc.data();
        if (doc.id !== "2022-08") {
          const totalGuesses =
            (data.scores[1] ? data.scores[1] * 1 : 0) +
            (data.scores[2] ? data.scores[2] * 2 : 0) +
            (data.scores[3] ? data.scores[3] * 3 : 0) +
            (data.scores[4] ? data.scores[4] * 4 : 0) +
            (data.scores[5] ? data.scores[5] * 5 : 0) +
            (data.scores[6] ? data.scores[6] * 6 : 0) +
            (data.scores["X"] ? data.scores["X"] * 6 : 0);
          const average = totalGuesses / data.gamesPlayed;
          wordleMonths.push({
            month: doc.id,
            avg: average,
          });
          if (average > maxAvg) {
            setMaxAvg(average);
          }
        }
      });
      setMonthsScores(wordleMonths);
      setLoading(false);
    };
    if ((!monthsScores || !maxAvg) && !loading) {
      setLoading(true);
      getWordles();
    }
  }, [firestore, maxAvg, monthsScores, loading]);

  return (
    <div>
      <Header title="Admin Stats" />
      <div className="">
        <div className="py-5 px-4 sm:py-6 sm:px-6">
          <div className="h-60 sm:h-56 pt-4">
            <div className="flex flex-col items-center w-full max-w-screen-md">
              <div className="flex items-end flex-grow w-full mt-2 space-x-2 sm:space-x-3">
                {monthsScores && monthsScores.length
                  ? monthsScores.map((month, index) => {
                      const options = { year: "numeric", month: "short" };
                      const dateArray = month.month.split("-");
                      const monthLabel = new Date(
                        dateArray[0],
                        dateArray[1] - 1
                      ).toLocaleDateString("en-US", options);
                      return (
                        <Bar
                          key={index}
                          average={month.avg}
                          label={monthLabel}
                          maxAverage={maxAvg}
                        />
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminStats;
