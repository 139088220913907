import React, { useState, useEffect } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { isEmpty } from 'lodash';
import { CheckCircleIcon, HashtagIcon, PresentationChartLineIcon } from '@heroicons/react/outline';
import Spinner from '../../components/Spinner';


const MainStats = ({ userId }) => {
  const [totalWordles, setTotalWordles] = useState(null);
  const [successRate, setSuccessRate] = useState(null);
  const [average, setAverage] = useState(null);
  const firestore = useFirestore();

  useEffect(() => {
    const getTotal = async () => {
      const totalSnap = await firestore.collection('users').doc(userId).collection('statistics').doc('totalResults').get();
      const total = totalSnap.data();

      if (!isEmpty(total) || (total && total.gamesPlayed > 0)) {
        setTotalWordles(total.gamesPlayed)
        setSuccessRate(((total.wins / total.gamesPlayed) * 100).toFixed(0) + "%");
      } else {
        setTotalWordles('0');
        setSuccessRate('0%');
      }
    }

    const getAverage = async () => {
      const averageSnap = await firestore.collection('users').doc(userId).collection('statistics').doc('totalAverages').get();
      const averages = averageSnap.data();

      if (!isEmpty(averages)) {
        setAverage(averages.avg)
      } else {
        setAverage('0')
      }
    }

    getAverage();
    getTotal();
  }, [firestore, userId])

  const stats = [
    { id: 1, name: 'Total Wordles', stat: totalWordles, icon: HashtagIcon, change: '122', changeType: 'increase' },
    { id: 2, name: 'Win Percentage', stat: successRate, icon: CheckCircleIcon, change: '5.4%', changeType: 'increase' },
    { id: 3, name: 'Average Score', stat: average, icon: PresentationChartLineIcon, change: '3.2%', changeType: 'decrease' },
  ]

  return (
    <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
      {stats.map((item) => (
        <div
          key={item.id}
          className="relative bg-white py-5 px-4 sm:py-6 sm:px-6 shadow rounded-lg overflow-hidden"
        >
          <dt>
            <div className="absolute bg-primary-reg rounded-md p-3">
              <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
            </div>
            <p className="ml-16 text-sm font-medium text-gray-500 truncate">{item.name}</p>
          </dt>
          <dd className="ml-16 flex items-baseline">
            <span className="text-2xl font-semibold text-gray-900">
              {
                item.stat
                  ? item.stat
                  : <Spinner height="24" color="#538F4F" />
              }
            </span>
            {/* <p
                className={classNames(
                  item.changeType === 'increase' ? 'text-primary-reg' : 'text-red-600',
                  'ml-2 flex items-baseline text-sm font-semibold'
                )}
              >
                {item.changeType === 'increase' ? (
                  <ArrowSmUpIcon className="self-center flex-shrink-0 h-5 w-5 text-primary-reg" aria-hidden="true" />
                ) : (
                  <ArrowSmDownIcon className="self-center flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />
                )}

                <span className="sr-only">{item.changeType === 'increase' ? 'Increased' : 'Decreased'} by</span>
                {item.change}
              </p> */}
          </dd>
        </div>
      ))}
    </dl>
  )
}

export default MainStats;