import React, { useContext } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Main from './Main';
import { routes } from '../../routes';
import Feedback from "feeder-react-feedback";
import "feeder-react-feedback/dist/feeder-react-feedback.css";
import { AppContext } from '../../contexts';


const Layout = () => {
  const { userInfo } = useContext(AppContext);

  return (
    <div>
      <div className="min-h-screen bg-gray-100">
        <Navbar routes={routes} />
        <div className="py-8 px-4 sm:px-8">
          <div className=" mx-auto lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="hidden lg:block lg:col-span-2">
              <Sidebar routes={routes} />
            </div>
            <main className="lg:col-span-10 pb-32 sm:pb-0">
              <Main routes={routes} />
            </main>
          </div>
        </div>
      </div>
      {
        userInfo && (
          <Feedback
            projectId="62440f46401f5d00040a6d8a"
            emailDefaultValue={userInfo.email}
          />
        )
      }
    </div>
  )
}

export default Layout;