import {
  HomeIcon,
  ChartBarIcon,
  UserGroupIcon,
  InformationCircleIcon,
  CogIcon,
} from "@heroicons/react/solid";
import Dashboard from "./scenes/Dashboard";
import Friends from "./scenes/Friends";
import Instructions from "./scenes/Instructions";
import Leagues from "./scenes/Leagues";
import Letter from "./scenes/Letter";
import MyScores from "./scenes/MyScores";
import PlayerDetail from "./scenes/PlayerDetail";
import ScoreDetail from "./scenes/ScoreDetail";
import Settings from "./scenes/Settings";
import Support from "./scenes/Support";
import ProPage from "./scenes/ProPage";

export const routes = [
  {
    exact: true,
    name: "My Dashboard",
    path: `/`,
    icon: HomeIcon,
    display: "main",
    component: Dashboard,
    paywall: true,
  },
  {
    exact: false,
    name: "My Leagues",
    path: `/leagues`,
    icon: UserGroupIcon,
    display: "main",
    component: Leagues,
    paywall: false,
  },
  {
    exact: false,
    name: "My Scores",
    path: `/scores`,
    icon: ChartBarIcon,
    display: "main",
    component: MyScores,
    paywall: false,
  },
  {
    name: "Instructions",
    path: "/instructions",
    display: "main",
    icon: InformationCircleIcon,
    component: Instructions,
  },
  {
    name: "Settings",
    path: "/settings",
    display: "main",
    icon: CogIcon,
    component: Settings,
  },
  {
    name: "Support",
    path: "/support",
    display: "secondary",
    external: false,
    component: Support,
  },
  {
    name: "Player Detail",
    path: "/player/:playerId",
    display: "none",
    component: PlayerDetail,
    exact: true,
    paywall: true,
  },
  {
    name: "Score Detail",
    path: "/player/:playerId/score/:scoreId",
    display: "none",
    component: ScoreDetail,
    exact: true,
    paywall: true,
  },
  {
    name: "Friends",
    path: "/friends",
    display: "none",
    component: Friends,
  },
  {
    name: "Letter",
    path: "/letter",
    display: "none",
    component: Letter,
  },
  {
    name: "Pro Page",
    path: "/pro",
    display: "none",
    component: ProPage,
  },
];
