import { ArrowSmLeftIcon } from "@heroicons/react/solid";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../contexts";

const Letter = ({ history }) => {
  const [loggedMixpanel, setLoggedMixpanel] = useState(false);
  const { userId, mixpanel } = useContext(AppContext);

  useEffect(() => {
    if (!loggedMixpanel && userId) {
      mixpanel.track("View Letter", {
        distinct_id: userId,
      });
      setLoggedMixpanel(true);
    }
  }, [loggedMixpanel, mixpanel, userId]);

  return (
    <div className="relative overflow-hidden">
      <div>
        <ArrowSmLeftIcon
          onClick={history.goBack}
          className="h-10 w-10 bg-gray-200 hover:bg-gray-300 mr-2 rounded-full p-2"
        />
      </div>
      <div className="mt-2 relative px-4 sm:px-6 lg:px-8 bg-white py-10 shadow rounded-lg">
        <div className="max-w-4xl mt-6 prose prose-indigo prose-lg text-black mx-auto">
          <p className="font-semibold text-lg">Hello Everyone,</p>
          <br />
          <p className="text-base">
            First off, I just want to say thank you to all of you who are here.
            Building Save My Wordle has been so much fun, and seeing that others
            have enjoyed it has been a blast. I've loved this project and hope
            it has brought some fun to you too.
          </p>
          <br />
          <p className="text-base">
            If you are here, that probably means that you have seen that we have
            implemented a paywall. And I'm sure that you are bummed. To be
            frank, I have gone back and forth on this for quite a while now.
            Because of that, I wanted to share a quick note and give some
            transparency to why we have made this decision.
          </p>
          <br />
          <p className="text-base">
            Save My Wordle grew quickly, and grew a little bigger than I
            thought. And I want you to know, I loved this -- this has been my
            favorite project that I've ever worked on. Unfortunately, an
            unforseen side effect of this was just the cost that it took to keep
            the platform up and running. Originally, this is why I intrudoced
            Save My Wordle Pro. To those of you who signed up, I am even more
            grateful. It definitely helped make a dent in the costs.
          </p>
          <br />
          <p className="text-base">
            We are implementing these changes in an effort to keep Save My
            Wordle going. I know that this means we may lose some of you, but my
            hope is that by making these changes many will stick around and that
            we'll be able to keep moving forward with this app. If there are
            features that would want to see, feel free to send them my way and
            we can put them into our plans.
          </p>
          <br />
          <p className="text-base">
            The good news is that the{" "}
            <Link
              className="text-primary-reg font-semibold hover:text-primary-dark"
              to="/leagues"
            >
              Leagues
            </Link>{" "}
            and{" "}
            <Link
              className="text-primary-reg font-semibold hover:text-primary-dark"
              to="/scores"
            >
              My Scores
            </Link>{" "}
            features will still be free to everyone. Feel free to keep
            submitting your scores, creating leagues, and inviting your friends.
          </p>
          <br />
          <p className="text-base">
            If you have any questions and would like to chat, feel free to reach
            out to me at conner@savemywordle.com. If you decide to stay, thank
            you! If you don't, still, thank you! And I mean that. I've loved
            this project and can't wait to keep it moving forward.
          </p>
          <br />
          <p className="text-base">Best,</p>
          <br />
          <p className="text-base font-semibold">Conner</p>
        </div>
      </div>
    </div>
  );
};

export default Letter;
