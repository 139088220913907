import React from 'react';
import { Header } from '../../../ui/Header';
import AllLeagues from './AllLeagues';
import MyLeagues from './MyLeagues';

const LeaguesHome = () => {
  return (
    <div>
      <Header
        title="My Leagues"
        actions={[
          {
            title: 'Create League',
            type: 'link',
            metadata: {
              linkTo: '/leagues/create'
            }
          }
        ]}
      />
      <MyLeagues />
      <AllLeagues />
    </div>
  )
}

export default LeaguesHome;