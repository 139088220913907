import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const SecureRoute = ({ path, component, location, auth, ...rest }) => {
  const authenticated = auth.uid;
  const Component = component;
  const userLoaded = auth.isLoaded;

  return (
    <Route
      path={path}
      render={props => {
        if (!authenticated && userLoaded) {
          return (
            <Redirect
              to={`/login?redirectUri=${location.pathname}${location.search}`}
            />
          );
        } else if (authenticated && userLoaded) {
          return <Component {...props} {...rest} />;
        }
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

export default connect(mapStateToProps)(SecureRoute);