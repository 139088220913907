import { CheckCircleIcon, QuestionMarkCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import React from 'react';
import { classNames } from '../../utils';

const Tile = ({ result }) => {
  return (
    <div
      className={classNames(
        result === 'absent'
          ? ''
          : result === 'present'
            ? 'bg-yellow-400'
            : result === 'correct'
              ? 'bg-primary-900'
              : null
        , 'w-1/5 border rounded h-8 sm:h-9.5 flex justify-center items-center group'
      )}
    >
      <span>
        {
          result === 'correct'
            ? <CheckCircleIcon className="h-4 text-white" />
            : result === 'present'
              ? <QuestionMarkCircleIcon className="h-4 text-white" />
              : <XCircleIcon className="h-4 text-gray-300" />
        }
      </span>
    </div>
  )
}

const ScoreChart = ({ username, score, evaluations }) => {

  return (
    <div className="py-5 px-4 sm:py-6 sm:px-6 bg-white rounded-lg shadow">
      <div>
        <p className="text-sm font-medium text-gray-500 truncate">{username} Score</p>
        <span className="text-3xl font-semibold text-gray-900">
          {score}<span className="text-gray-500 font-normal text-lg">/6</span>
        </span>
      </div>
      <div>
        <p className="text-sm font-medium text-gray-500 truncate pt-4 pb-2">{username} Guesses</p>
      </div>
      <div
        className="h-52 sm:h-56"
      >
        <div className="">
          {Object.keys(evaluations).map(row => (
            <div key={row} className="flex gap-x-1 pb-0.5">
              <div className="text-sm mr-0.5 w-3 flex items-center">
                <span className="text-sm text-gray-500">
                  {Number(row) + 1}
                </span>
              </div>
              {Object.keys(evaluations[row]).map(col => {
                return (
                  <Tile key={`${row}-${col}`} result={evaluations[row][col]} />
                )
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ScoreChart;